import { Slot } from '@radix-ui/react-slot';
import { clsx } from 'clsx';

import type { ElementProps } from '../../types.js';
import {
  navigationHeaderStyles,
  navigationItemStyles,
  navigationListStyles,
  navigationLogoStyles,
  navigationWrapperStyles,
} from './navigation.css.js';

export function Navigation({ className, ...props }: ElementProps<'nav'>) {
  return (
    <nav
      className={clsx(navigationWrapperStyles, className)}
      data-test="navigation-wrapper"
      role="navigation"
      {...props}
    />
  );
}

export function NavigationHeader({ className, ...props }: ElementProps<'div'>) {
  return (
    <div
      className={clsx(navigationHeaderStyles, className)}
      data-test="navigation-header"
      {...props}
    />
  );
}

export function NavigationLogo({ className, ...props }: ElementProps<'div'>) {
  return <div className={clsx(navigationLogoStyles, className)} {...props} />;
}

export function NavigationList({ className, ...props }: ElementProps<'div'>) {
  return <div className={clsx(navigationListStyles, className)} {...props} />;
}

export type NavigationItemProps = ElementProps<'div'> & {
  isActive?: boolean;
  asChild?: boolean;
  className?: string;
};

export function NavigationItem({
  asChild,
  isActive,
  className,
  ...props
}: NavigationItemProps) {
  const Component = asChild ? Slot : 'div';

  return (
    <Component
      {...props}
      className={clsx(className, navigationItemStyles)}
      data-active={isActive || undefined}
    />
  );
}
