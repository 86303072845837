import { CompanionAdContainer } from '@iheartradio/web.accomplice/display-ads';
import {
  type Companion,
  COMPANION_CLICK_THROUGH_URL_CLASS,
  CompanionResourceType,
} from '@iheartradio/web.playback';
import { isNull } from '@iheartradio/web.utilities';
import { memo } from 'react';
import { isDeepEqual, isNullish } from 'remeda';

export type CompanionAdProps = {
  companion: Companion | null;
  fullscreen?: boolean;
};

export function isImplementedCompanion(companion: Companion) {
  if (isNull(companion)) {
    return false;
  }

  return isNullish(companion.resourceType) ? false : (
      [
        CompanionResourceType.Static,
        CompanionResourceType.IFrame,
        CompanionResourceType.HTML,
      ].includes(companion.resourceType)
    );
}

function CompanionAdBase({ companion, fullscreen = false }: CompanionAdProps) {
  if (isNull(companion)) {
    return null;
  }

  const isImplemented = isImplementedCompanion(companion);

  if (!isImplemented) {
    console.group();
    console.groupCollapsed();
    console.info('Not a Static/IFrame/HTML Companion Ad');
    console.dir(companion, { depth: null });
    console.groupEnd();
  }

  return isNullish(companion.content) || !isImplemented ?
      null
    : <CompanionAdContainer
        dangerouslySetInnerHTML={{ __html: companion.content }}
        data-fullscreen={fullscreen}
        data-test="companion-ad-creative"
        height={companion.height}
        onClick={() => {
          if (companion.clickThroughUrl) {
            const img = document.createElement('img');
            img.className = COMPANION_CLICK_THROUGH_URL_CLASS;
            img.width = 0;
            img.height = 0;
            img.src = companion.clickThroughUrl;
            document.body.append(img);
          }
        }}
        width={companion.width}
      />;
}

export const CompanionAd = memo(CompanionAdBase, (previousProps, nextProps) => {
  return isDeepEqual(previousProps, nextProps);
});
