import { isString } from '@iheartradio/web.utilities';
import {
  type DropdownMenuProps,
  Content,
  Group,
  Item,
  Portal,
  Root,
  Separator,
  Sub,
  SubContent,
  SubTrigger,
  Trigger,
} from '@radix-ui/react-dropdown-menu';
import { clsx } from 'clsx/lite';
import {
  type ComponentPropsWithoutRef,
  type ElementRef,
  type HTMLAttributes,
  Children,
  forwardRef,
} from 'react';

import { ChevronRight } from '../../icons/chevron-right.js';
import { lightDark } from '../../media.js';
import { rainbowSprinkles } from '../../rainbow-sprinkles.css.js';
import { vars } from '../../theme-contract.css.js';
import { menuContentStyles, menuItemStyles } from './menu.css.js';

export type MenuRootProps = DropdownMenuProps;

const MenuRoot = Root;
MenuRoot.displayName = 'MenuRoot';

const MenuTrigger = Trigger;
MenuTrigger.displayName = 'MenuTrigger';

const MenuGroup = Group;
MenuGroup.displayName = 'MenuGroup';

const MenuPortal = Portal;
MenuPortal.displayName = 'MenuPortal';

const MenuSub = Sub;
MenuSub.displayName = 'MenuSub';

const MenuSubTrigger = forwardRef<
  ElementRef<typeof SubTrigger>,
  ComponentPropsWithoutRef<typeof SubTrigger>
>(function MenuSubTrigger({ className, children, ...props }, ref) {
  return (
    <SubTrigger
      className={clsx(menuItemStyles, className)}
      ref={ref}
      {...props}
    >
      {children}
      <ChevronRight
        style={{ maxHeight: '18px', maxWidth: '18px', marginLeft: 'auto' }}
      />
    </SubTrigger>
  );
});

const MenuSubContent = forwardRef<
  ElementRef<typeof SubContent>,
  ComponentPropsWithoutRef<typeof SubContent>
>(function MenuSubContent({ className, ...props }, ref) {
  return (
    <MenuPortal>
      <SubContent
        className={clsx(menuContentStyles, className)}
        ref={ref}
        {...props}
      />
    </MenuPortal>
  );
});

const MenuContent = forwardRef<
  ElementRef<typeof Content>,
  ComponentPropsWithoutRef<typeof Content>
>(function MenuContent({ className, sideOffset = 4, ...props }, ref) {
  return (
    <MenuPortal>
      <Content
        className={clsx(menuContentStyles, className)}
        ref={ref}
        role="menu"
        sideOffset={sideOffset}
        {...props}
      />
    </MenuPortal>
  );
});

export type MenuItemProps = ComponentPropsWithoutRef<typeof Item>;

const MenuItem = forwardRef<
  ElementRef<typeof Item>,
  ComponentPropsWithoutRef<typeof Item>
>(function MenuItem({ children, className, ...props }, ref) {
  const textStyles = rainbowSprinkles({
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  });

  return (
    <Item className={clsx(menuItemStyles, className)} ref={ref} {...props}>
      {Children.map(children, child =>
        isString(child) ?
          <span
            {...textStyles.otherProps}
            className={textStyles.className}
            style={textStyles.style}
          >
            {child}
          </span>
        : child,
      )}
    </Item>
  );
});

const MenuSeparator = forwardRef<
  ElementRef<typeof Separator>,
  ComponentPropsWithoutRef<typeof Separator>
>(function MenuSeparator({ className, style, ...props }, ref) {
  const baseStyles = rainbowSprinkles({
    backgroundColor: lightDark(vars.color.gray150, vars.color.gray500),
    height: '0.1rem',
    width: '100%',
  });

  return (
    <Separator
      className={clsx(baseStyles.className, className)}
      ref={ref}
      role="separator"
      style={{ ...baseStyles.style, ...style }}
      {...props}
    />
  );
});

function MenuRightSlot({
  className,
  style,
  ...props
}: HTMLAttributes<HTMLSpanElement>) {
  const baseStyles = rainbowSprinkles({
    marginLeft: 'auto',
  });

  return (
    <span
      className={clsx(baseStyles.className, className)}
      style={{ ...baseStyles.style, ...style }}
      {...props}
    />
  );
}

export {
  MenuContent,
  MenuGroup,
  MenuItem,
  MenuPortal,
  MenuRightSlot,
  MenuRoot,
  MenuSeparator,
  MenuSub,
  MenuSubContent,
  MenuSubTrigger,
  MenuTrigger,
};
