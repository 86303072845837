import { Play as PlayIcon } from '@iheartradio/web.accomplice/icons';
import { Player } from '@iheartradio/web.companion';
import { Playback } from '@iheartradio/web.playback';

import { useUser } from '~app/contexts/user';

import { type OptionalPlayProps, Play } from './play';
import {
  type UseFavoritesPlayProps,
  useFavoritesPlay,
} from './use-favorites-play';

export type FavoritesPlayProps = UseFavoritesPlayProps & OptionalPlayProps;

export function FavoritesPlay({
  color = 'white',
  size = 40,
  ...props
}: FavoritesPlayProps) {
  const { isAnonymous } = useUser();
  const play = useFavoritesPlay(props);

  return isAnonymous ?
      <Player.Play color={color} onClick={play.onClick}>
        <PlayIcon size={size} />
      </Player.Play>
    : <Play
        color={color}
        size={size}
        type={Playback.StationType.Favorites}
        {...props}
      />;
}
