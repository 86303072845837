import { lightDark, vars } from '@iheartradio/web.accomplice';
import { Box } from '@iheartradio/web.accomplice/box';
import type {
  CarouselContentProps,
  CarouselContextProps,
  CarouselProps,
  CarouselSlideProps,
} from '@iheartradio/web.accomplice/carousel';
import {
  Carousel,
  CarouselContent,
  CarouselHandler,
  CarouselSlide,
  useCarousel,
} from '@iheartradio/web.accomplice/carousel';
import { Flex } from '@iheartradio/web.accomplice/flex';
import { Loading } from '@iheartradio/web.accomplice/icons';
import { Text } from '@iheartradio/web.accomplice/text';
import { isPlainObject } from '@iheartradio/web.utilities/object';
import { rgba } from 'polished';
import type { ReactNode } from 'react';
import { firstBy, identity, values } from 'remeda';

export type CardCarouselKind =
  | 'content'
  | 'featured'
  | 'news'
  | 'row'
  | 'ranker';

const getSlidesToShowMap = ({
  hasDescription,
}: {
  hasDescription?: boolean;
}): Record<CardCarouselKind, NonNullable<CarouselProps['slidesToShow']>> => {
  return {
    content: {
      xsmall: 3,
      small: 3,
      medium: hasDescription ? 3 : 4,
      large: hasDescription ? 3 : 5,
      xlarge: hasDescription ? 5 : 7,
    },
    featured: {
      xsmall: 1,
      small: 1,
      shmedium: 2,
      medium: 2,
      large: 2,
      xlarge: 3,
    },
    ranker: {
      xsmall: 2,
      small: 3,
      medium: 4,
      xlarge: 5,
      xxlarge: 6,
    },
    news: {
      xsmall: 1,
      small: 2,
      medium: 3,
      large: 3,
      xlarge: 5,
    },
    row: {
      xsmall: 1,
      small: 1,
      medium: 2,
      large: 1,
      xlarge: 2,
    },
  };
};

export type CardCarouselProps<T extends object = object> = {
  gradientColor?: string;
  children?: CarouselContentProps<T>['children'];
  description?: ReactNode;
  dependencies?: CarouselContentProps<T>['dependencies'];
  isLoading?: boolean;
  renderEmptyState?: CarouselContentProps<T>['renderEmptyState'];
  items?: CarouselContentProps<T>['items'];
  kind: CardCarouselKind;
  title?: ReactNode;
  showScrollButtons?: boolean;
};

function CardCarouselEmptyState() {
  return (
    <Flex
      alignItems="center"
      height={{ mobile: '18rem', large: '21rem' }}
      justifyContent="center"
      width="100%"
    >
      <Text
        as="p"
        color={lightDark(vars.color.gray600, vars.color.gray300)}
        kind="body-3"
      >
        No results currently available for this filter
      </Text>
    </Flex>
  );
}

export function CardCarousel<T extends object>(props: CardCarouselProps<T>) {
  const {
    gradientColor,
    title,
    description,
    dependencies,
    children,
    isLoading,
    items,
    kind,
    showScrollButtons,
    renderEmptyState,
    ...restProps
  } = props;

  const slidesToShow = getSlidesToShowMap({ hasDescription: !!description })[
    kind
  ];

  const carouselContent = (
    <Carousel {...restProps} isLoading={isLoading} slidesToShow={slidesToShow}>
      <CarouselHandler showScrollButtons={showScrollButtons}>
        {!description ?
          <Text
            as="h3"
            css={{
              color: lightDark(vars.color.gray600, vars.color.brandWhite),
            }}
            kind={{ mobile: 'h4', large: 'h3' }}
          >
            {title}
          </Text>
        : null}
      </CarouselHandler>
      <Box position="relative">
        {isLoading ?
          <Flex
            alignItems="center"
            // These colors are the main background colors with 0.5 opacity applied
            background={lightDark(rgba('#F6F8F9', 0.5), rgba('#000000', 0.5))}
            bottom="0"
            height={{ mobile: '18rem', large: '21rem' }}
            justifyContent="center"
            left="0"
            position="absolute"
            right="0"
            top="0"
            width="100%"
            zIndex="10"
          >
            <Loading size={40} />
          </Flex>
        : null}
        <CarouselContent<T>
          dependencies={dependencies}
          items={items}
          renderEmptyState={renderEmptyState ?? CardCarouselEmptyState}
        >
          {children}
        </CarouselContent>
      </Box>
    </Carousel>
  );

  return (
    <Box
      background={
        gradientColor ?
          `linear-gradient(0.25turn, transparent, ${rgba(gradientColor, 0.3)} 50%, transparent)`
        : undefined
      }
      data-test="card-carousel"
      paddingY="$16"
      position="relative"
      width="100%"
    >
      {description ?
        <Flex
          alignItems={{ mobile: 'left', medium: 'center' }}
          data-test="featured-layout"
          direction={{ mobile: 'column', medium: 'row' }}
        >
          <Flex
            alignItems="left"
            direction="column"
            gap="$8"
            minWidth={{
              medium: '$5',
              large: '$3',
              xlarge: '$3',
            }}
            padding={{ mobile: '$0 $16', large: '$0 $0 $0 $32' }}
          >
            <Text
              as="h3"
              css={{ width: '100%' }}
              kind={{ mobile: 'h4', large: 'h3' }}
            >
              {title}
            </Text>
            <Text as="p" kind={{ mobile: 'body-4', large: 'body-3' }}>
              {description}
            </Text>
          </Flex>
          {carouselContent}
        </Flex>
      : carouselContent}
    </Box>
  );
}

export { type CarouselSlideProps as CardCarouselSlideProps };
export const CardCarouselSlide = CarouselSlide;

export function useCardCarousel():
  | (CarouselContextProps & { maxSlidesToShow: number })
  | undefined {
  try {
    const contextValue = useCarousel();

    return {
      ...contextValue,
      maxSlidesToShow:
        isPlainObject(contextValue.slidesToShow) ?
          (firstBy(values(contextValue.slidesToShow), [
            identity(),
            'desc',
          ]) as number)
        : contextValue.slidesToShow,
    };
  } catch {
    return undefined;
  }
}
