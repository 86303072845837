import { VisuallyHidden } from '@iheartradio/web.accomplice';
import { Box } from '@iheartradio/web.accomplice/box';
import { Button } from '@iheartradio/web.accomplice/button';
import {
  Dialog,
  DialogTitle,
  DialogTrigger,
} from '@iheartradio/web.accomplice/dialog';
import { CancelFilled } from '@iheartradio/web.accomplice/icons';
import { LogotypeEarlyAccess } from '@iheartradio/web.accomplice/logos';
import { Text } from '@iheartradio/web.accomplice/text';
import { useFetcher } from '@remix-run/react';
import { type ReactNode, useEffect, useState } from 'react';
import { ClientOnly } from 'remix-utils/client-only';

import type { UserEducationModalAction } from '~app/routes/close-user-education-modal';

type UserEducationDialogProps = {
  showDialog: boolean;
};

const DialogListItem = ({
  children,
  Icon,
}: {
  children: ReactNode;
  Icon?: ReactNode;
}) => (
  <Box alignItems="center" asChild display="flex" gap="$8">
    <li>
      <div>{Icon ?? <CancelFilled size={16} />}</div>
      <Text as="p" kind="body-3">
        {children}
      </Text>
    </li>
  </Box>
);

export const UserEducationDialog = ({
  showDialog = false,
}: UserEducationDialogProps) => {
  const [open, setOpen] = useState(showDialog);

  const tryNowFetcher = useFetcher<UserEducationModalAction>();
  const doNotShowAgainFetcher = useFetcher<UserEducationModalAction>();

  const isSubmitting = tryNowFetcher.state === 'submitting';
  const isLoading = tryNowFetcher.state === 'loading';
  const isDone = tryNowFetcher.state === 'idle' && tryNowFetcher.data != null;

  const isSubmittingDoNotShow = doNotShowAgainFetcher.state === 'submitting';
  const isLoadingDoNotShow = doNotShowAgainFetcher.state === 'loading';
  const isDoneDoNotShow =
    doNotShowAgainFetcher.state === 'idle' &&
    doNotShowAgainFetcher.data != null;

  useEffect(() => {
    if (isDone || isDoneDoNotShow) {
      setOpen(false);
    }
  }, [isDone, isDoneDoNotShow]);

  return (
    <ClientOnly>
      {() => (
        <DialogTrigger isOpen={open}>
          <VisuallyHidden />
          <Dialog>
            <LogotypeEarlyAccess
              aria-label="iHeart Header Logotype"
              media="tablet"
              size={40}
            />
            <DialogTitle>Welcome to try the new iHeart site! </DialogTitle>
            <Box>
              This is a limited access site to gather feedback from a select
              group of listeners like yourself. These features are unavailable
              but coming soon:
            </Box>
            <Box asChild display="flex" flexDirection="column" gap="$16">
              <ul>
                <DialogListItem>
                  Following live and artist stations, podcasts, and playlists to
                  save to your Library
                </DialogListItem>
                <DialogListItem>Thumbing up or down a song</DialogListItem>
                <DialogListItem>Sharing content on social media</DialogListItem>
              </ul>
            </Box>
            <Box>
              <tryNowFetcher.Form
                action="/close-user-education-modal"
                method="POST"
              >
                <Button
                  color="red"
                  hideOutlineOnFocus
                  isDisabled={isSubmitting || isLoading}
                  kind="primary"
                  size={{ mobile: 'small', medium: 'large' }}
                  type="submit"
                >
                  Try it now
                </Button>
              </tryNowFetcher.Form>
              <doNotShowAgainFetcher.Form
                action="/close-user-education-modal"
                method="POST"
              >
                <input name="dontshowagain" type="hidden" value="true" />
                <Button
                  color="white"
                  hideOutlineOnFocus
                  isDisabled={isSubmittingDoNotShow || isLoadingDoNotShow}
                  kind="tertiary"
                  size={{ mobile: 'small', medium: 'large' }}
                  type="submit"
                >
                  Got it! Don’t show again
                </Button>
              </doNotShowAgainFetcher.Form>
            </Box>
          </Dialog>
        </DialogTrigger>
      )}
    </ClientOnly>
  );
};
