import type { DefaultSelectOption } from '@iheartradio/web.accomplice/select-field';
import { SelectOption } from '@iheartradio/web.accomplice/select-field';
import { MediaServerURL } from '@iheartradio/web.assets';
import { Playback } from '@iheartradio/web.playback';
import { isString, slugify } from '@iheartradio/web.utilities';
import { useNavigation, useSearchParams } from '@remix-run/react';
import { type Key, useCallback } from 'react';
import { isNonNullish, isNullish } from 'remeda';

import { useItemSelected } from '~app/analytics/use-item-selected';
import { getCardId } from '~app/api/utilities';
import {
  CardCarousel,
  CardCarouselSlide,
} from '~app/components/card-carousel/card-carousel';
import { CarouselSelect } from '~app/components/carousel-select/carousel-select';
import { ContentCardImage } from '~app/components/content-card/content-card';
import { RankedContentCard } from '~app/components/content-card/ranked-content-card';
import { useIsMobile } from '~app/contexts/is-mobile';
import {
  SelectInteractionProvider,
  useSelectInteraction,
} from '~app/contexts/select-interaction';
import { Play } from '~app/playback/controls/play';
import { AnalyticsContext } from '~app/utilities/constants';
import { buildPodcastUrl } from '~app/utilities/urls';

import type { HomeServerLoaderData } from '../.server/loader';

export type TopPodcasts = Awaited<HomeServerLoaderData['topPodcasts']>;

export type TopPodcast = Awaited<
  Exclude<HomeServerLoaderData['topPodcasts'], null>[number]
>;

export type PodcastsCategories = Awaited<
  Exclude<HomeServerLoaderData['podcastCategories'], null>
>;

export type SelectedCategoryID = Awaited<
  HomeServerLoaderData['currentPodcastCategory']
>;

type PodcastCategorySelectProps = {
  categories: PodcastsCategories;
  selectedCategoryID: string;
  stateKey: string;
};

const sectionTitle = 'Top Podcasts';

const BY_CATEGORIES_STATE_KEY = 'byCategory';

export function PodcastCategorySelect(props: PodcastCategorySelectProps) {
  const { categories, selectedCategoryID, stateKey } = props;
  const [_searchParams, setSearchParams] = useSearchParams();
  const [isOpen, setIsOpen] = useSelectInteraction();

  const onSelectionChange = useCallback(
    (key: Key) => {
      if (isString(key) && key !== selectedCategoryID.toString()) {
        setSearchParams(
          params => {
            params.set('category', key.toString());
            return params;
          },
          {
            replace: true,
            preventScrollReset: true,
            state: { stateKey },
          },
        );
      }
    },
    [selectedCategoryID, setSearchParams, stateKey],
  );

  return (
    <CarouselSelect
      aria-label="podcast-categories"
      data-test="podcast-categories"
      defaultSelectedKey={selectedCategoryID}
      isOpen={isOpen}
      items={[
        { key: '0', label: 'All Categories', value: '0' },
        ...categories.reduce<DefaultSelectOption[]>((result, topic) => {
          const topicId = getCardId(topic);

          if (!isNullish(topicId) && !isNullish(topic.title)) {
            result.push({ key: topicId, label: topic.title, value: topicId });
          }

          return result;
        }, []),
      ]}
      key={selectedCategoryID.toString()}
      name="podcastcategories"
      onBlur={() => setIsOpen(false)}
      onOpenChange={(isOpen: boolean) => {
        setIsOpen(isOpen);
      }}
      onSelectionChange={onSelectionChange}
      sectionTitle={sectionTitle}
    >
      {item => (
        <SelectOption key={item.key} textValue={item.label}>
          {item.label}
        </SelectOption>
      )}
    </CarouselSelect>
  );
}

function Slide({
  pageName,
  podcast,
  index,
  sectionPosition,
}: {
  pageName: string;
  podcast: TopPodcast;
  index: number;
  sectionPosition: number;
}) {
  const { onItemSelected } = useItemSelected();
  const isMobile = useIsMobile();

  const { playing } = Play.usePodcastPlay({
    id: Number(podcast?.id),
    context: 0,
  });

  const podcastUrl = buildPodcastUrl({
    podcastId: Number(podcast?.id),
    slug: podcast && 'slug' in podcast ? podcast.slug : '',
  });

  return podcast.id ?
      <CardCarouselSlide
        href={podcastUrl}
        onAction={() => {
          onItemSelected({
            pageName,
            section: slugify(sectionTitle),
            context: AnalyticsContext.Carousel,
            itemPosition: index,
            sectionPosition,
            assets: {
              asset: {
                id: `${Playback.StationType.Podcast}|${podcast?.id}`,
                name: podcast?.title ?? '',
                type: Playback.StationType.Podcast,
              },
            },
          });
        }}
      >
        {({ isFocused, isHovered }) => (
          <RankedContentCard
            {...{ isFocused, isHovered }}
            image={
              <ContentCardImage
                alt={podcast?.title ?? ''}
                decoding={index === 0 ? 'sync' : 'auto'}
                index={index}
                src={MediaServerURL.fromCatalog({
                  type: 'podcast',
                  id: podcast.id,
                }).quality(isMobile ? 40 : 50)}
                width={isMobile ? 70 : 140}
              />
            }
            imageButton={
              <Play.Podcast context={0} id={Number(podcast?.id)} size={48} />
            }
            isActive={playing}
            linesForTitle={2}
            rank={index + 1}
            title={podcast?.title ?? ''}
          />
        )}
      </CardCarouselSlide>
    : null;
}

export const TopPodcastsCarousel = ({
  pageName,
  podcasts,
  podcastCategories,
  selectedCategoryID,
  sectionPosition,
}: {
  pageName: string;
  podcasts: TopPodcasts;
  podcastCategories: PodcastsCategories;
  selectedCategoryID?: SelectedCategoryID;
  sectionPosition: number;
}) => {
  const navigation = useNavigation();
  const loadedCategory = `category=${selectedCategoryID}`;

  const carouselItems = podcasts
    .filter(podcast => isNonNullish(podcast))
    .map((item, index) => ({ ...item, index }));

  return (
    <SelectInteractionProvider>
      <CardCarousel
        isLoading={
          navigation.state === 'loading' &&
          navigation.location.search.includes('category') &&
          !navigation.location.search.includes(loadedCategory)
        }
        items={carouselItems}
        key={selectedCategoryID ?? 0}
        kind="ranker"
        title={
          <PodcastCategorySelect
            categories={podcastCategories}
            selectedCategoryID={selectedCategoryID?.toString() ?? '0'}
            stateKey={BY_CATEGORIES_STATE_KEY}
          />
        }
      >
        {carouselItem => {
          const { index, ...podcast } = carouselItem;

          return (
            <Slide
              index={index}
              key={podcast.id}
              pageName={pageName}
              podcast={podcast}
              sectionPosition={sectionPosition}
            />
          );
        }}
      </CardCarousel>
    </SelectInteractionProvider>
  );
};
