import { Play as PlayIcon } from '@iheartradio/web.accomplice/icons';
import { Player } from '@iheartradio/web.companion';
import { Playback } from '@iheartradio/web.playback';

import { useUser } from '~app/contexts/user';
import { isPremiumUser } from '~app/utilities/user';

import { type OptionalPlayProps, Play } from './play';
import {
  type UsePlaylistPlayProps,
  usePlaylistPlay,
} from './use-playlist-play';

export type PlaylistPlayProps = (Omit<UsePlaylistPlayProps, 'type'> & {
  type?: Playback.StationType.Playlist | Playback.StationType.PlaylistRadio;
  isDisabled?: boolean;
}) &
  OptionalPlayProps;

export function PlaylistPlay({
  color = 'white',
  size = 40,
  type = Playback.StationType.Playlist,
  isDisabled = false,
  ...rest
}: PlaylistPlayProps) {
  const userIsPremium = isPremiumUser(useUser());

  const props = {
    ...rest,
  };
  const play = usePlaylistPlay({ ...props, type });

  if (type === Playback.StationType.Playlist) {
    if (userIsPremium) {
      return (
        <Play
          color={color}
          isDisabled={isDisabled}
          seed={(rest as Playback.PlaylistStation)?.seed}
          size={size}
          type={Playback.StationType.Playlist}
          {...props}
        />
      );
    }
    return (
      <Player.Play color={color} onClick={play.onClick}>
        <PlayIcon size={size} />
      </Player.Play>
    );
  } else {
    if (userIsPremium) {
      return (
        <Play
          color={color}
          isDisabled={isDisabled}
          seed={(rest as Playback.PlaylistStation)?.seed}
          size={size}
          type={Playback.StationType.Playlist}
          {...props}
        />
      );
    }
    // if playlist profile hero... return play button with shuffle = true; else ...
    return (
      <Play
        color={color}
        isDisabled={isDisabled}
        shuffle={true}
        size={size}
        type={Playback.StationType.PlaylistRadio}
        {...props}
      />
    );
  }
}
