import { vars } from '@iheartradio/web.accomplice';
import { Box } from '@iheartradio/web.accomplice/box';
import { addToast } from '@iheartradio/web.accomplice/toast';
import {
  keyframes,
  Player as CompanionPlayer,
} from '@iheartradio/web.companion';
import { Playback, PlayerErrorCode } from '@iheartradio/web.playback';
import { useTheme } from '@iheartradio/web.remix-shared/react/theme.js';
import { isNil, isNotBlank } from '@iheartradio/web.utilities';
import { useFetcher, useLocation, useNavigation } from '@remix-run/react';
import type * as React from 'react';
import { useEffect, useReducer, useRef, useState } from 'react';
import { $path } from 'remix-routes';
import { ClientOnly } from 'remix-utils/client-only';
import { useHydrated } from 'remix-utils/use-hydrated';

import { CompanionAd } from '~app/ads/display/companion-ad';
import { NAV_AD_SIZES } from '~app/ads/display/nav-ad';
import { AdsTargetingState, useTargetingReady } from '~app/contexts/ads';
import { useUser } from '~app/contexts/user';
import type { AppsFlyerSdk } from '~app/hooks/apps-flyer/use-apps-flyer';
import type { PlayerContentTypeRecommendationsLoader } from '~app/routes/api.v1.player.$contentType.$stationId.recommendations';

import { Actions } from './actions';
import { AppsFlyerHelper } from './apps-flyer';
import { ControlSet } from './control-set';
import { Time } from './controls/time';
import { FullScreen } from './full-screen';
import { Metadata } from './metadata/metadata';
import { playback } from './playback';
import { isPremiumStation, premiumStationFallback } from './playback-helpers';
import { VideoPlayer } from './video-player';

const slideUpDesktop = keyframes({
  '100%': { transform: 'translateY(0)', zIndex: vars.zIndex[10] },
});

const slideUpMobile = keyframes({
  '99%': { transform: 'translateY(-6rem)', zIndex: vars.zIndex[3] },
  '100%': { transform: 'translateY(-6rem)', zIndex: vars.zIndex[10] },
});

interface PlayerProps {
  appsFlyerSdk?: AppsFlyerSdk;
}

export type PlayerBaseProps = {
  loaded: React.MutableRefObject<boolean>;
  playerBarRef: React.RefObject<HTMLDivElement>;
  isFullScreen: boolean;
  setIsFullScreen: React.Dispatch<React.SetStateAction<boolean>>;
  appsFlyerSdk?: AppsFlyerSdk;
  adBreak: boolean;
  fetcher: ReturnType<
    typeof useFetcher<PlayerContentTypeRecommendationsLoader>
  >;
  companion: JSX.Element | null;
  metadata: Playback.Metadata;
  station: Playback.Station;
  type: Playback.Ads['type'];
  videoPlayerRef: React.RefObject<HTMLDivElement>;
};

export function Player({ appsFlyerSdk }: PlayerProps) {
  const navigation = useNavigation();
  const theme = useTheme();

  const player = playback.usePlayer();
  const state = playback.useState();
  const lastError = playback.useError();
  const { station } = state;
  const metadata = playback.useMetadata();

  const { adBreak, type, current } = playback.useAds();
  const showVideoPlayer = adBreak && type === 'video';

  const [isFullScreen, setIsFullScreen] = CompanionPlayer.useFullScreen();

  const loaded = useRef(false);
  const loading = useRef(false);
  const loadAttempts = useRef(0);

  const videoPlayerRef = useRef<HTMLDivElement>(null);
  const playerBarRef = useRef<HTMLDivElement>(null);
  const location = useLocation();

  // This is an ['official'](https://legacy.reactjs.org/docs/hooks-faq.html#is-there-something-like-forceupdate) workaround for `forceUpdate`
  const [reload, forceRerender] = useReducer(x => x + 1, 0);

  const user = useUser();
  const sessionRef = useRef(user?.sessionId);

  // If the user has logged out, we want to reload the player, in case what was loaded previously
  // cannot be played by a (now) anonymous user
  useEffect(() => {
    // If the session id stored in ref does not match the current user session
    if (
      sessionRef.current !== user?.sessionId &&
      isPremiumStation(station.type)
    ) {
      // then we use our `useReducer` call to force a re-render
      forceRerender();
      // set the loading flags to false, so that the effect below will not exit early
      loaded.current = false;
      loading.current = false;
    }
  }, [user?.sessionId, station.type]);

  const hydrated = useHydrated();
  // De-structuring the `load` method so that hook dependencies can be exhaustively provided below
  // This, along with using a `key` in `useFetcher` will give us the same fetcher load method each
  // time, so `useEffect` will pass referential equality check
  const { load: recsLoad, data: recsData } =
    useFetcher<PlayerContentTypeRecommendationsLoader>({
      key: `${station.id}`,
    });

  useEffect(() => {
    const [error] = lastError?.slice(-1) ?? [];
    // if we're still trying to load a premium fallback or the default station, don't pop an error
    // toast just yet;
    if (!loaded.current && loadAttempts.current < 2) return;
    if (
      isNil(error) ||
      isNil(error.message) ||
      ('code' in error &&
        (error.code === PlayerErrorCode.PlayAttemptFailed ||
          error.code === PlayerErrorCode.ApiError))
    ) {
      return;
    }

    addToast({
      kind: 'error',
      text: error.message,
    });
  }, [lastError]);

  useEffect(() => {
    if (station.type && station.id) {
      recsLoad(
        $path('/api/v1/player/:contentType/:stationId/recommendations', {
          contentType: station.type,
          stationId: station.id.toString(),
        }),
      );
    }
  }, [station.type, station.id, recsLoad]);

  const [companion, setCompanion] = useState<JSX.Element | null>(null);
  useEffect(() => {
    if (isNotBlank(current?.companions)) {
      const companionToRender =
        current?.companions?.find(companion => {
          return (
            companion.height === NAV_AD_SIZES[0][1] &&
            companion.width === NAV_AD_SIZES[0][0]
          );
        }) ?? null;
      if (companionToRender) {
        setCompanion(<CompanionAd companion={companionToRender} fullscreen />);
      } else {
        setCompanion(null);
      }
    } else {
      setCompanion(null);
    }
  }, [current]);

  useEffect(() => {
    setIsFullScreen(false);
  }, [location, setIsFullScreen]);

  const checkReadyToLoad = useTargetingReady();

  useEffect(() => {
    if (loaded.current || loading.current) return;

    const doLoad = (station: Playback.Station) => {
      if (!isNil(station)) {
        loading.current = true;
        player
          .load({
            ...station,
            targeting: AdsTargetingState.get('targetingParams'),
          })
          .then(() => {
            loaded.current = true;
            loading.current = false;
            return null;
          })
          .catch(() => {
            loadAttempts.current += 1;
            loading.current = false;

            doLoad(premiumStationFallback(station, loadAttempts.current));
          });
      } else {
        loading.current = true;
        player
          .load({
            id: 1469,
            context: 0,
            type: Playback.StationType.Live,
            targeting: AdsTargetingState.get('targetingParams'),
          })
          .then(() => {
            loaded.current = true;
            loading.current = false;
            return null;
          })
          .catch(() => (loading.current = false));
      }
    };

    // If the AdsTargeting is ready, then go ahead an load
    if (checkReadyToLoad()) {
      doLoad(station);
    } else {
      // Otherwise, check every 100ms until AdsTargeting is ready
      (async function doCheck() {
        setTimeout(() => {
          if (checkReadyToLoad()) {
            doLoad(station);
          } else {
            doCheck();
          }
        }, 100);
      })();
    }
  }, [player, station, checkReadyToLoad, reload]); // include 'reload' in the deps array so that this effect will run if we need to reload the player

  useEffect(() => {
    const { current: videoPlayer } = videoPlayerRef;

    let observed = false;
    let observerInstantiated = false;

    const observer = new IntersectionObserver(
      entries => {
        const { top } = entries[0].boundingClientRect;
        const playerHeight =
          playerBarRef.current?.getBoundingClientRect().height ?? 0;
        const offset = top + playerHeight;

        if (videoPlayer && offset < 0 && observed) {
          videoPlayer.dataset.scrolled = '1';
        } else {
          observed = true;
        }
      },
      {
        rootMargin: '100% 0%',
        threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
      },
    );
    const scrollTarget =
      document.querySelector('#scroll-target')?.nextElementSibling;

    if (scrollTarget && showVideoPlayer) {
      observerInstantiated = true;
      observer.observe(scrollTarget);
    }

    if (navigation.state === 'loading' && observerInstantiated && videoPlayer) {
      videoPlayer.dataset.scrolled = '1';
    }

    return () => {
      if (videoPlayer && !adBreak) {
        videoPlayer.dataset.scrolled = '0';
      }
      if (scrollTarget && observerInstantiated) {
        observer.unobserve(scrollTarget);
      }
    };
  }, [navigation.state, player, adBreak, showVideoPlayer]);

  useEffect(() => {
    if (!player.initialized && hydrated) {
      (async function doInitialize() {
        await player.initialize();
      })();
    }
  }, [player, hydrated]);

  return (
    <Box
      alignSelf="end"
      animationDuration="350ms"
      animationFillMode="forwards"
      animationName={
        loaded.current ?
          { mobile: `${slideUpMobile}`, large: `${slideUpDesktop}` }
        : undefined
      }
      animationTimingFunction="ease-out"
      bottom="0"
      gridArea="player"
      height="min-content"
      position="fixed"
      ref={playerBarRef}
      transform="translateY(100%)"
      width="100%"
      zIndex={{ mobile: '$3', large: '$10' }}
    >
      <ClientOnly>
        {() => (
          <CompanionPlayer.Root data-test="player-root">
            <AppsFlyerHelper appsFlyerSdk={appsFlyerSdk} />
            <FullScreen
              adBreak={adBreak}
              carouselData={recsData}
              companion={companion}
              isFullScreen={isFullScreen}
              metadata={metadata}
              station={station}
              theme={theme}
              type={type}
            />
            <VideoPlayer ref={videoPlayerRef} />
            <Metadata />
            <ControlSet />
            <Time />
            <Actions />
          </CompanionPlayer.Root>
        )}
      </ClientOnly>
    </Box>
  );
}
