import clsx from 'clsx';
import type { ReactNode } from 'react';
import type { ComboBoxProps, ListBoxItemProps } from 'react-aria-components';
import {
  Button as RACButton,
  ComboBox as RACCombobox,
  Input as RACInput,
  Label as RACLabel,
  ListBox as RACListBox,
  ListBoxItem as RACListBoxItem,
  Popover as RACPopover,
} from 'react-aria-components';
import { isFunction } from 'remeda';

import { Check } from '../../icons/check.js';
import {
  type RainbowSprinkles,
  rainbowSprinkles,
} from '../../rainbow-sprinkles.css.js';
import { vars } from '../../theme-contract.css.js';
import { baseButtonStyles, buttonRecipe } from '../button/button.css.js';
import { Text } from '../text/text.js';
import {
  buttonStyles,
  comboBoxInputStyles,
  listBoxItemStyles,
  popoverStyles,
  selectIconStyles,
} from './combobox.css.js';

export type ComboboxItem = { id: number | string; name: string };

export interface ComboboxProps<T extends object = ComboboxItem>
  extends Omit<ComboBoxProps<T>, 'children'> {
  label?: string | ReactNode;
  css?: RainbowSprinkles;
  children: ReactNode | ((item: T) => ReactNode);
  placeholder?: string;
  inputSize?: number;
  divider?: boolean;
}

export const Combobox = (props: ComboboxProps<ComboboxItem>) => {
  const {
    children,
    css,
    label,
    divider = false,
    items,
    inputSize = 20,
    ...restProps
  } = props;

  const result = rainbowSprinkles({
    position: 'relative',
    ...css,
  });

  return (
    <RACCombobox
      allowsEmptyCollection={true}
      className={result.className}
      menuTrigger="focus"
      style={result.style}
      {...restProps}
    >
      <RACLabel>{label}</RACLabel>
      {/* Do not remove - This Button comes from React Aria, it is not visible in the UI, but rendering it is required for full Combobox functionality */}
      <RACButton className={buttonStyles} />
      <RACInput
        className={clsx(
          buttonRecipe({ kind: 'primary', size: 'small' }),
          baseButtonStyles,
          comboBoxInputStyles,
        )}
        data-test="combobox-input"
        size={inputSize}
        type="text"
      />
      <RACPopover
        className={popoverStyles}
        data-show-divider={divider}
        data-test="combobox-popover"
        maxHeight={200}
      >
        <RACListBox
          data-test="combobox-list"
          items={items}
          renderEmptyState={() => (
            <Text
              as="p"
              css={{ textAlign: 'center', padding: vars.space[8] }}
              kind={{ mobile: 'subtitle-4', medium: 'subtitle-4' }}
            >
              No results found. Please try again.
            </Text>
          )}
        >
          {children}
        </RACListBox>
      </RACPopover>
    </RACCombobox>
  );
};

export function ListBoxItem({ children, ...props }: ListBoxItemProps) {
  return (
    <RACListBoxItem className={listBoxItemStyles} {...props}>
      {renderProps => {
        if (isFunction(children)) {
          return children(renderProps);
        }

        const { isSelected } = renderProps;

        return (
          <>
            {children}
            {isSelected ?
              <Check className={selectIconStyles} size={18} />
            : null}
          </>
        );
      }}
    </RACListBoxItem>
  );
}
