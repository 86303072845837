import { type IconProps, Icon } from './icon.js';

export function AdChoices(props: Omit<IconProps, 'children'>) {
  return (
    <Icon aria-label="Ad Choices" {...props}>
      <svg viewBox="0 0 24 24">
        <path d="M19.66 13.79c.7-.37 1.34-.79 1.74-1.5.08-.18.08-.26 0-.51-.28-.83-.96-1.27-1.68-1.66l-8.28-4.5-2.02-1.1-3.6-1.95c-.2-.11-.42-.2-.64-.3l-.36-.17a1.37 1.37 0 0 0-1.01 0c-1.04.5-1.27 1.4-1.27 2.46v15.3c.02.48.02.57.18 1.04a2 2 0 0 0 .93 1.04c.17.08.42.08.67 0l.86-.42c.64-.3 1.27-.61 1.89-.95.33-.18.64-.48.8-.8.4-.77-.13-1.52-.98-1.45-.16 0-.32.06-.48.12l-.2.06c-.53.16-.96-.03-1.12-.56-.08-.28-.1-.57-.1-.87V6.66L5 6.2c.09-.8.7-1.18 1.44-.88.13.05.26.11.38.18l9.66 5.5c.16.08.31.18.45.3.46.44.46.97-.03 1.39-.2.16-.44.29-.67.42l-5.29 2.73a3.5 3.5 0 0 1-.29.13l-.16.07v-3.6c0-.66-.42-1.08-1.03-1.1-.62 0-1.06.44-1.06 1.1v.65c-.01 1.5-.02 2.99.01 4.48 0 .3.2.67.43.9.5.5 1.14.53 1.78.19 3-1.63 6.02-3.25 9.04-4.87Z" />
        <path d="M8.39 9.63c0 .6.49 1.07 1.1 1.07.62 0 1.1-.46 1.1-1.07.03-.6-.5-1.12-1.12-1.11-.6 0-1.08.51-1.08 1.1Z" />
      </svg>
    </Icon>
  );
}
