import { Playback } from '@iheartradio/web.playback';
import { slugify } from '@iheartradio/web.utilities';
import { isNullish } from 'remeda';

import { playlistAnalyticsData } from '~app/analytics/playlist-analytics-helper';
import { useItemSelected } from '~app/analytics/use-item-selected';
import type { PlaylistRecs } from '~app/api/types';
import {
  CardCarousel,
  CardCarouselSlide,
} from '~app/components/card-carousel/card-carousel';
import {
  ContentCard,
  ContentCardImage,
} from '~app/components/content-card/content-card';
import { useIsMobile } from '~app/contexts/is-mobile';
import { useUser } from '~app/contexts/user';
import { Play } from '~app/playback/controls/play';
import { AnalyticsContext } from '~app/utilities/constants';
import { buildPlaylistUrl } from '~app/utilities/urls';
import { isAnonymousUser, isPremiumUser } from '~app/utilities/user';

const sectionTitle = 'Recommended Playlists';

function Slide({
  playlist,
  pageName,
  index,
  sectionPosition,
}: {
  playlist: PlaylistRecs[number];
  pageName: string;
  index: number;
  sectionPosition: number;
}) {
  const isMobile = useIsMobile();
  const { onItemSelected } = useItemSelected();
  const user = useUser();
  const isPremium = isPremiumUser(user);

  const type =
    isPremium ?
      Playback.StationType.Playlist
    : Playback.StationType.PlaylistRadio;

  const playlistAssets = playlistAnalyticsData({
    isCurated: playlist.curated,
    playlistId: playlist.id,
    profileId: user?.profileId?.toString() ?? '',
    playlistUserId: playlist.userId,
    playlistName: playlist.name,
    userType: user?.subscription?.type,
    isAnonymous: isAnonymousUser(user) ?? true,
    playlistType: type,
  });

  const { playing } = Play.usePlaylistPlay({
    context: 0,
    id: `${Number(playlist.userId)}::${playlist.id}`,
    type,
  });

  return (
    <CardCarouselSlide
      href={buildPlaylistUrl({ ...playlist })}
      onAction={() => {
        onItemSelected({
          pageName,
          section: slugify(sectionTitle),
          context: AnalyticsContext.Carousel,
          itemPosition: index,
          sectionPosition,
          assets: {
            asset: playlistAssets.asset,
          },
        });
      }}
    >
      <ContentCard
        description={playlist.description}
        image={
          <ContentCardImage
            alt={playlist.name}
            decoding="auto"
            index={index}
            src={playlist.urls?.image}
            width={isMobile ? 75 : 150}
          />
        }
        imageButton={
          <Play.Playlist
            context={0}
            id={`${Number(playlist.userId)}::${playlist.id}`}
            shuffle={false}
            size={48}
            type={type}
          />
        }
        isActive={playing}
        title={playlist.name}
      />
    </CardCarouselSlide>
  );
}

export function RecommendedPlaylistsCarousel({
  pageName,
  playlists,
  sectionPosition,
}: {
  pageName: string;
  playlists: PlaylistRecs;
  sectionPosition: number;
}) {
  if (isNullish(playlists) || playlists?.length < 5) {
    return null;
  }

  const items = playlists.map((item, index) => ({ ...item, index }));

  return (
    <CardCarousel items={items} kind="content" title={sectionTitle}>
      {({ index, ...playlist }) => (
        <Slide
          index={index}
          key={playlist.id}
          pageName={pageName}
          playlist={playlist}
          sectionPosition={sectionPosition}
        />
      )}
    </CardCarousel>
  );
}
