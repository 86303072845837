import type {
  CardImageProps,
  CardProps,
} from '@iheartradio/web.accomplice/card';
import {
  Card,
  CardBody,
  CardImage,
  CardPreview,
  CardSubtitle,
  CardTitle,
} from '@iheartradio/web.accomplice/card';
import type { HTMLAttributeAnchorTarget, ReactNode } from 'react';
import { isNonNullish } from 'remeda';
import type { SetOptional } from 'type-fest';

import { useImageLoadingProps } from '~app/hooks/use-image-loading-props';

export type GenreCardProps = Omit<CardProps, 'isActive'> & {
  description?: string;
  href?: string;
  image: ReactNode;
  linkTarget?: HTMLAttributeAnchorTarget;
  title?: string;
};

/**
 * The `<GenreCard />` component is used to display Genre content in a list.
 */
export function GenreCard(props: GenreCardProps) {
  const { description, image, title, ...restProps } = props;

  const showCardBody = isNonNullish(title) || isNonNullish(description);

  return (
    <Card data-test="genre-card" {...restProps} orientation="vertical">
      {showCardBody ?
        <CardBody>
          <CardTitle lines={2}>{title}</CardTitle>
          {description ?
            <CardSubtitle lines={2}>{description}</CardSubtitle>
          : null}
        </CardBody>
      : null}
      <CardPreview shape="square">{image}</CardPreview>
    </Card>
  );
}

export type GenreCardImageProps = SetOptional<CardImageProps, 'src'> & {
  index?: number;
};

export function GenreCardImage({ src, index, ...props }: GenreCardImageProps) {
  const { loadingProps } = useImageLoadingProps(index);

  if (!src) return null;

  return (
    <CardImage
      aspectRatio="16 / 9"
      placeholder={false}
      src={src}
      {...loadingProps}
      {...props}
    />
  );
}
