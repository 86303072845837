import { type IconProps, Icon } from './icon.js';

export function Instagram(props: Omit<IconProps, 'children'>) {
  return (
    <Icon aria-label="Instagram" {...props}>
      <svg viewBox="3 3 18 18">
        <g clipPath="url(#a)">
          <path
            clipRule="evenodd"
            d="M12 7.378a4.622 4.622 0 1 0 0 9.244 4.622 4.622 0 0 0 0-9.244ZM12 15a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z"
            fillRule="evenodd"
          />
          <path d="M17.884 7.196a1.08 1.08 0 1 1-2.16 0 1.08 1.08 0 0 1 2.16 0Z" />
          <path
            clipRule="evenodd"
            d="M12 3c-2.444 0-2.75.01-3.71.054-.959.044-1.613.196-2.185.419-.6.225-1.145.58-1.594 1.038-.458.45-.813.993-1.039 1.594-.222.572-.374 1.226-.418 2.184C3.01 9.25 3 9.556 3 12s.01 2.75.054 3.71c.044.959.196 1.613.419 2.185.226.6.58 1.145 1.038 1.594.45.458.993.813 1.594 1.038.572.223 1.227.375 2.184.419C9.25 20.99 9.556 21 12 21s2.751-.01 3.71-.054c.959-.044 1.613-.196 2.185-.419a4.602 4.602 0 0 0 2.633-2.632c.222-.572.374-1.226.418-2.184.044-.96.054-1.267.054-3.711s-.01-2.75-.054-3.71c-.044-.959-.196-1.613-.418-2.185A4.41 4.41 0 0 0 19.49 4.51a4.412 4.412 0 0 0-1.595-1.039c-.572-.222-1.226-.374-2.184-.418C14.75 3.01 14.444 3 12 3Zm0 1.622c2.403 0 2.688.009 3.637.052.877.04 1.354.187 1.671.31a2.78 2.78 0 0 1 1.035.673c.299.29.529.644.673 1.035.123.317.27.794.31 1.671.043.95.052 1.234.052 3.637s-.009 2.688-.052 3.637c-.04.878-.187 1.354-.31 1.671a2.98 2.98 0 0 1-1.708 1.708c-.317.124-.794.27-1.671.31-.949.043-1.234.053-3.637.053s-2.688-.01-3.637-.053c-.877-.04-1.354-.186-1.671-.31a2.788 2.788 0 0 1-1.035-.673 2.788 2.788 0 0 1-.673-1.035c-.123-.317-.27-.793-.31-1.671-.043-.949-.052-1.234-.052-3.637s.009-2.688.052-3.637c.04-.877.187-1.354.31-1.67.144-.392.374-.745.673-1.036a2.78 2.78 0 0 1 1.035-.673c.317-.123.794-.27 1.671-.31.95-.043 1.234-.052 3.637-.052Z"
            fillRule="evenodd"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path d="M0 0h24v24H0z" />
          </clipPath>
        </defs>
      </svg>
    </Icon>
  );
}
