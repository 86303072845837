import { METADATA_DOMAIN } from '@iheartradio/web.remix-shared/metadata/constants.js';
import { createWebStorage } from '@iheartradio/web.utilities/create-storage';
import type { Routes as RouteParams } from 'remix-routes';

import type { Market } from '~app/api/types';

// Currently these are the only three "Made for you" playlists we provide users with.
// If this changes in the future, we will need to update this array, or find a way to dynamically
// grab these ids, such as a new API endpoint. Additionally, the ids are stored in a Set since
// a Set is more performant than a Map.
export const madeForYouPlaylistIds = new Set(['new4u', 'chill4u', 'workout4u']);
export const NON_MUSIC_GENRES = [9, 15, 98, 93];
export const NON_PLAYLIST_GENRE = [3, 10, 13, 8, 19];

export const DEFAULT_POPULAR_GENRES = 102;
export const PODCAST_POPULAR_CATEGORY_ID = 82;
export const DEFAULT_TIMEZONE = 'America/New_York';
export const DEFAULT_TIMEZONE_ABBREVIATION = 'EST';

export const INPUT_MAX_LENGTH_DEFAULT = 100;

export const ITEMS_FOR_ARTIST_PROFILE = 12;
export const ITEMS_FOR_ALBUM_PROFILE = 12;
export const METADATA_APP_LOGO = 'fb_logo.png'; // TODO: update this with the method to resolve URLs based on environment context
export const METADATA_APPLE_TOUCH_ICON = 'apple-touch-icon.png'; // TODO: update this with the method to resolve URLs based on environment context
export const METADATA_GLOBAL_TITLE =
  'Listen to Your Favorite Music, Podcasts, and Radio Stations for Free!';
export const METADATA_GLOBAL_KEYWORDS =
  'iHeart, iHeartRadio, Internet, Music, Talk, Listen, Live, Artist, Song, Playlist, On Demand, Discover, Personalized, Free, App, Online';
export const METADATA_GLOBAL_DESCRIPTION =
  'All your favorite music, podcasts, and radio stations available for free. Listen to thousands of live radio stations or create your own artist stations and playlists. Get the latest music and podcasts, from your favorite artists and creators.';
export const METADATA_PLAYER_DIMENSIONS = { width: '450', height: '300' };
export const METADATA_DEFAULT_CANONICAL = `https://www.${METADATA_DOMAIN}/`;
export const METADATA_DEFAULT_IMAGE = `/${METADATA_APP_LOGO}`;
export const DELETE_PLAYLIST_ACTION = 'DELETE_PLAYLIST_ACTION';
export const RENAME_PLAYLIST_ACTION = 'RENAME_PLAYLIST_ACTION';
export const ALL_GENRE = 'All Genres';
export const ADD_TO_PLAYLIST_AUTHENTICATION_MESSAGE =
  'Log in to add this to your playlist and access your Library';

type Routes = keyof RouteParams;
type RouteMap = { [key: string]: Routes | RouteMap };

export type ItemSelectedParams = {
  pageName: string;
  index: number;
  sectionPosition: number;
  itemPosition: number;
  section: string;
};

export const Routes = Object.freeze({
  ArtistAlbums: '/artist/:artistSlug/albums',
  ArtistSongs: '/artist/:artistSlug/songs',
  Login: '/login/:action?',
  PodcastProfile: '/podcast/:podcastSlug',
  PodcastProfileSearch: '/podcast/:podcastSlug/search',
  SignUp: '/sign-up',
  Search: '/search',
  Browse: Object.freeze({
    Root: '/browse',
    Live: '/browse/live',
    Artists: '/browse/artists',
    Playlists: '/browse/playlists',
    Podcasts: '/browse/podcasts',
  }),
  Library: Object.freeze({
    Live: '/library/live',
    Artists: '/library/artists',
    Playlists: '/library/playlists',
    Podcasts: '/library/podcasts',
  }),
  Live: Object.freeze({
    Contests: '/live/:liveSlug/contests',
    Station: '/live/:liveSlug',
    NowPlaying: '/live/:liveSlug/now-playing',
  }),
  Favorites: Object.freeze({
    Station: '/favorites/:userId?',
    NowPlaying: '/favorites/:userId/now-playing',
  }),
}) satisfies RouteMap;

export const REG_GATE_TRIGGER_TYPES = {
  ADD_TO_PLAYLIST: 'add_to_playlist',
  ARTIST_STATION_FAVORITE: 'artist_station_favorite',
  ARTIST_STATION_THUMB: 'artist_station_thumb',
  LIVE_STATION_FAVORITE: 'live_station_favorite',
  LIVE_STATION_THUMB: 'live_station_thumb',
  PLAYLIST_FAVORITE: 'playlist_favorite',
  PODCAST_FAVORITE: 'podcast_favorite',
  MADE_FOR_YOU: 'made_for_you',
  LOG_IN: 'log_in',
};

export enum AnalyticsContext {
  Row = 'row',
  Carousel = 'carousel',
  Grid = 'grid',
  Overflow = 'overflow',
}

export enum LibraryActions {
  Follow = 'FOLLOW',
  Unfollow = 'UNFOLLOW',
  Delete = 'DELETE',
}

export enum FollowUnfollowContentTypes {
  Artist = 'artist',
  Live = 'live',
  Podcast = 'podcast',
  Playlist = 'playlist',
}

export enum AnalyticsSaveTypes {
  Follow = 'follow',
  Unfollow = 'unfollow',
}

export enum EducationalFeatures {
  PodcastFilter = 'education-podcastFilter',
}

export const UPDATE_PREFERENCES = 'UPDATE_PREFERENCES';

export const defaultMarket: Market = {
  name: 'NEWYORK-NY',
  marketId: 159,
  loc: {
    lat: 40.748_001_098_632_81,
    lon: -73.986_000_061_035_16,
  },
  stationCount: 73,
  stateAbbreviation: 'NY',
  stateId: '37',
  stateName: 'New York',
  city: 'New York',
  countryName: 'United States',
  countryId: '1',
  countryAbbreviation: 'US',
} as const;

export const delayInPopOverCloseForMobile = 1000;

export const defaultHeroBackgroundColor = '$brand-white';

export const tabStorage = createWebStorage({
  deserializer: <V extends string>(value: string) => {
    return decodeURIComponent(value) as V;
  },
  seed: {
    browseTab: '/browse/live',
    libraryTab: '/library/live',
  },
  serializer: encodeURIComponent,
  type: 'session',
});
