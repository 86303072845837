import { isFunction } from 'remeda';

/**
 * fetchClientData is a naive data-fetcher that returns that json data from a response as the type
 * supplied in the generic.
 *
 * @param response A Response promise (returned from `fetch`) or a function that returns a Response promise
 * @param fallback If supplied and if the status of the response is not 2xx, then this data is returned
 * @returns T
 */
export const fetchClientData = async <T>(
  response: Promise<Response> | (() => Promise<Response>),
  fallback: Exclude<T, undefined>,
): Promise<T> => {
  try {
    const response_ = isFunction(response) ? await response() : await response;
    if (!response_.ok) {
      console.warn(`Fetching ${response_.url} failed: ${response_.statusText}`);
      return fallback;
    }
    return response_.json() as T;
  } catch (error: unknown) {
    const message =
      error instanceof Error ? error.message : JSON.stringify(error);

    console.warn(`Fetching failed - ${message}`);
    return fallback;
  }
};

/**
 *
 * @param url URL to fetch
 * @param timeout Time in ms to wait before aborting the request
 * @returns Promise<Response>
 */
export const fetchWithTimeout = async (url: string | URL, timeout = 5000) => {
  return fetch(url, { signal: AbortSignal.timeout(timeout) });
};
