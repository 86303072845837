import type { User } from '@iheartradio/web.config';
import { createStateContext } from '@iheartradio/web.remix-shared/react/create-state-context.js';
import { type ReactNode, useEffect } from 'react';

export interface UserProviderProps {
  children?: ReactNode;
  value?: User | undefined;
}

const { Provider, useContext } = createStateContext<User | null>('User', null);

function UserContextProvider({
  children,
  value,
}: {
  children: ReactNode;
  value: User;
}) {
  useEffect(() => {
    window.sessionStorage.setItem('pid', String(value.profileId));
  }, [value.profileId]);

  return <Provider value={value}>{children}</Provider>;
}

function useUser() {
  const [user] = useContext();
  return user ?? ({} as User);
}

export { UserContextProvider, useUser };
