import { MediaServerURL } from '@iheartradio/web.assets';
import type { Theme } from '@iheartradio/web.companion';
import { Player } from '@iheartradio/web.companion';
import { Playback } from '@iheartradio/web.playback';
import { isNotBlank, isNull, isString } from '@iheartradio/web.utilities';
import { type useFetcher, Link as RouterLink } from '@remix-run/react';
import { memo, useMemo } from 'react';
import { isDeepEqual, isEmpty, isNonNullish, isNullish } from 'remeda';

import {
  CardCarousel,
  CardCarouselSlide,
} from '~app/components/card-carousel/card-carousel';
import {
  ContentCard,
  ContentCardImage,
} from '~app/components/content-card/content-card';
import { useIsMobile } from '~app/contexts/is-mobile';
import type { PlayerContentTypeRecommendationsLoader } from '~app/routes/api.v1.player.$contentType.$stationId.recommendations';
import {
  buildAlbumUrl,
  buildArtistTopSongsUrl,
  buildArtistUrl,
  buildLiveUrl,
  buildPlaylistUrl,
  buildPodcastUrl,
} from '~app/utilities/urls';

import { Actions } from './actions';
import { ControlSet } from './control-set';
import { Play } from './controls/play';
import { Time } from './controls/time';
import { getContentTitle, getSlideDataFromStation } from './helpers';
import { Metadata } from './metadata/metadata';
import type { StationRec } from './types';

function Slide({
  station,
  isMobile,
  theme,
}: {
  station: StationRec;
  isMobile: boolean;
  theme: Theme;
}) {
  const {
    stationId,
    stationDescription,
    stationTitle,
    imageUrl,
    imageButtonJSX,
  } = getSlideDataFromStation(station, isMobile, theme);

  const { playing } = Play.usePlay({
    id: Number(stationId),
    context: 0,
    type: station.stationType,
  });

  return (
    <CardCarouselSlide>
      <Player.Link as={RouterLink} to={station.url ?? '/'}>
        <ContentCard
          description={stationDescription ?? ''}
          image={
            <ContentCardImage
              alt={stationTitle ?? ''}
              src={imageUrl}
              width={isMobile ? 75 : 150}
            />
          }
          imageButton={imageButtonJSX}
          isActive={playing}
          title={stationTitle ?? ''}
        />
      </Player.Link>
    </CardCarouselSlide>
  );
}

const getMetadata = ({
  metadata,
  station,
}: {
  metadata: Exclude<Playback.Metadata, null>;
  station: Exclude<Playback.Station, null>;
}) => {
  const {
    artistId,
    albumId,
    albumName,
    id,
    image,
    name,
    description,
    subtitle,
    podcastId,
    podcastSlug,
    title,
  } = metadata.data;

  const link = (({ stationType }) => {
    switch (stationType) {
      case Playback.StationType.Album: {
        if (artistId && title && albumId && albumName) {
          return buildAlbumUrl({
            artist: { id: artistId, name: title },
            album: { id: albumId, name: albumName },
          });
        }
        break;
      }
      case Playback.StationType.Artist: {
        if (artistId && title) {
          return buildArtistUrl({ id: artistId, name: title });
        }
        break;
      }
      case Playback.StationType.TopSongs: {
        if (artistId) {
          return buildArtistTopSongsUrl({ id: artistId, name: title ?? '' });
        }
        break;
      }
      case Playback.StationType.Live: {
        if (id && name) {
          return buildLiveUrl({ name, id });
        }
        break;
      }
      case Playback.StationType.Podcast: {
        if (podcastId && podcastSlug) {
          return buildPodcastUrl({ podcastId, slug: podcastSlug });
        }
        break;
      }
      case Playback.StationType.Playlist:
      case Playback.StationType.PlaylistRadio: {
        const [playlistUserId, playlistId] = isString(id) ? id.split('::') : [];
        if (playlistId && playlistUserId) {
          return buildPlaylistUrl({
            name: title ?? '',
            userId: playlistUserId,
            id: playlistId,
          });
        }
        break;
      }
    }
  })({ stationType: station.type });

  const stationTitle =
    station.type === Playback.StationType.Podcast ? description : subtitle;

  return {
    link,
    stationTitle,
    image,
  };
};

export type FullScreenProps = {
  metadata: Playback.Metadata;
  station: Playback.Station;
  adBreak: boolean;
  type: Playback.Ads['type'];
  carouselData?:
    | ReturnType<
        typeof useFetcher<PlayerContentTypeRecommendationsLoader>
      >['data']
    | undefined;
  isFullScreen: boolean;
  companion: JSX.Element | null;
  theme: Theme;
};

export const FullScreen = memo(
  function FullScreen({
    metadata,
    station,
    adBreak,
    type,
    carouselData,
    isFullScreen,
    companion,
    theme,
  }: FullScreenProps) {
    const isMobile = useIsMobile();
    const recsCarousel = useMemo(() => {
      if (
        isNullish(carouselData?.carousel?.data) ||
        isEmpty(carouselData?.carousel?.data) ||
        isNullish(station?.type)
      ) {
        return null;
      }

      const recs = carouselData.carousel.data.filter(isNonNullish);

      return (
        <CardCarousel
          items={recs}
          key={`recs-${station.id}`}
          kind="content"
          showScrollButtons={!(adBreak && type === 'video')}
          title={getContentTitle(station.type)}
        >
          {rec => {
            return <Slide isMobile={isMobile} station={rec} theme={theme} />;
          }}
        </CardCarousel>
      );
    }, [
      carouselData,
      station?.type,
      station.id,
      adBreak,
      type,
      isMobile,
      theme,
    ]);

    if (isNull(metadata) || isNull(station)) {
      return null;
    }

    const { link, stationTitle, image } = getMetadata({ metadata, station });

    return (
      <Player.FullScreen
        actions={<Actions />}
        adBreak={adBreak}
        companion={companion}
        controls={<ControlSet isFullScreen={isFullScreen} />}
        header={
          isNotBlank(link) ?
            <Player.Link as={RouterLink} to={link} underline="hover">
              {stationTitle ?? ''}
            </Player.Link>
          : stationTitle
        }
        imageSrc={
          image ?
            MediaServerURL.fromURL(image)
              .ratio(1, 1)
              .scale(isMobile ? 250 : 500)
              .quality(isMobile ? 40 : 50)
              .toString()
          : ''
        }
        isFullScreen={isFullScreen}
        metadata={<Metadata />}
        time={<Time />}
      >
        {recsCarousel}
      </Player.FullScreen>
    );
  },
  (previousProps, nextProps) => {
    return isDeepEqual(previousProps, nextProps);
  },
);
