import { lightDark } from '@iheartradio/web.accomplice';
import { Box } from '@iheartradio/web.accomplice/box';
import { Button } from '@iheartradio/web.accomplice/button';
import { Flex } from '@iheartradio/web.accomplice/flex';
import { Text } from '@iheartradio/web.accomplice/text';
import {
  getResponsiveImgAttributes,
  MediaServerURL,
} from '@iheartradio/web.assets';
import { Image } from '@iheartradio/web.companion';
import { $path } from 'remix-routes';

import { useRegGateEvent } from '~app/analytics/use-reg-gate';
import { useIsMobile } from '~app/contexts/is-mobile';
import { REG_GATE_TRIGGER_TYPES, Routes } from '~app/utilities/constants';

const images = [
  'https://i.iheart.com/v3/url/aHR0cHMlM0ElMkYlMkZ3ZWItc3RhdGljLnBhZ2VzLmloZWFydC5jb20lMkZpbWclMkZkZWZhdWx0X2Zhdm9yaXRlLnBuZw==',
  'https://i.iheart.com/v3/re/new_assets/622922797e7648c322b6ebe4',
];

export const MadeForYouCTA = () => {
  const { onAnalyticsRegGateOpen } = useRegGateEvent();
  const isMobile = useIsMobile();

  return (
    <Flex
      direction="row"
      height="19.2rem"
      justifyContent="center"
      overflow="hidden"
      padding={{ mobile: '$16', large: '$16 $32' }}
      position="relative"
      zIndex="$0"
    >
      <Box
        backgroundImage={`url('${MediaServerURL.fromURL(images[0])
          .scale(125)
          .quality(1)
          .toString()}')`}
        backgroundPosition="center"
        backgroundSize="cover"
        filter="blur(4rem)"
        height="100%"
        position="absolute"
        top="$0"
        width="100%"
      />
      <Box
        background={lightDark(
          'linear-gradient(to right, rgba(246, 248, 249, 100%), rgba(255, 255, 255, 50%), rgba(246, 248, 249, 100%))',
          'linear-gradient(to right, rgba(0, 0, 0, 90%), rgba(0, 0, 0, 60%), rgba(0, 0, 0, 90%))',
        )}
        height="100%"
        position="absolute"
        top="$0"
        width="100%"
        zIndex="$1"
      />
      <Flex
        alignItems="center"
        direction="row"
        gap={{ mobile: '$24', large: '$64' }}
        justifyContent="center"
        zIndex="$2"
      >
        <Flex direction="column" gap="$16" justifyContent="center">
          <Flex direction="column" gap="$4" justifyContent="center">
            <Text as="h3" kind={{ mobile: 'h4', large: 'h3' }} lines={2}>
              Made For You
            </Text>
            <Text kind="body-3" lines={3}>
              Save more content and unlock your personalized playlists
            </Text>
          </Flex>
          <Flex direction="row" gap="$12">
            <Button
              color="red"
              href={$path(Routes.Login, { action: 'auth' })}
              kind="primary"
              onPress={() =>
                onAnalyticsRegGateOpen(REG_GATE_TRIGGER_TYPES.MADE_FOR_YOU)
              }
              size="small"
            >
              Log in
            </Button>
            <Button
              color="white"
              href={$path(Routes.SignUp)}
              kind="secondary"
              onPress={() =>
                onAnalyticsRegGateOpen(REG_GATE_TRIGGER_TYPES.MADE_FOR_YOU)
              }
              size="small"
            >
              Sign up
            </Button>
          </Flex>
        </Flex>
        <Flex
          aspectRatio={{ mobile: '1/1', xlarge: 'unset' }}
          direction="row"
          flexGrow="0"
          gap="$24"
          height="12.4rem"
        >
          <Image
            alt="Your Favorites Radio"
            aspectRatio="1 / 1"
            borderRadius="$6"
            height="auto"
            maxHeight="100%"
            {...getResponsiveImgAttributes(images[0], {
              width: isMobile ? 60 : 125,
              ratio: [1, 1],
              quality: isMobile ? 40 : 75,
            })}
          />
          <Image
            alt="Your Weekly Mixtape"
            aspectRatio="1 / 1"
            borderRadius="$6"
            display={{
              '@initial': 'none',
              '@xlarge': 'initial',
            }}
            height="auto"
            maxHeight="100%"
            {...getResponsiveImgAttributes(images[1], {
              width: isMobile ? 60 : 125,
              ratio: [1, 1],
              quality: isMobile ? 40 : 75,
            })}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};
