import { breakpoints } from '@iheartradio/web.accomplice';
import { Combobox, ComboboxItem } from '@iheartradio/web.accomplice/combobox';
import { useMediaQuery } from '@iheartradio/web.accomplice/hooks';
import { Text } from '@iheartradio/web.accomplice/text';
import { useSearchParams } from '@remix-run/react';

import { getMarketLabel } from '~app/api/markets';
import { useRootLoaderData } from '~app/hooks/use-root-loader-data';
import { useSetMarketId } from '~app/hooks/use-set-market-id';

export const MarketsDropdown = ({
  sectionTitle,
  stateKey,
}: {
  sectionTitle: string;
  stateKey?: string;
}) => {
  const {
    market: initialMarket,
    marketNamesById,
    orderedMarkets,
  } = useRootLoaderData();
  const [marketId, setMarketId] = useSetMarketId(initialMarket.marketId);

  const [_searchParams, setSearchParams] = useSearchParams();

  const isLargeBreakpoint = useMediaQuery(breakpoints.large);

  const items = orderedMarkets.map(market => ({
    id: market.marketId,
    name: getMarketLabel(market),
  }));

  const marketNameSize = getMarketLabel(marketNamesById[marketId]).length - 1;

  return (
    <Text
      as="div"
      css={{
        alignItems: 'center',
        display: 'flex',
        flexWrap: 'wrap',
        gap: '$8',
        justifyContent: { mobile: 'space-between', large: 'flex-start' },
        width: '100%',
      }}
      kind={{ mobile: 'h4', large: 'h3' }}
    >
      {sectionTitle}
      <Combobox
        aria-label="Market Search"
        css={{
          maxWidth: {
            xsmall: '20rem',
            medium: '100%',
          },
          width: 'auto',
          paddingRight: {
            small: '$8',
            touch: '$0',
          },
        }}
        defaultInputValue={getMarketLabel(marketNamesById[marketId])}
        // Specify items for the combobox, filtering is handled for us under the hood
        defaultItems={items}
        // we want to adjust it based on the length of the market name
        inputSize={isLargeBreakpoint ? marketNameSize : marketNameSize + 2}
        menuTrigger="focus"
        name="live-stations"
        onSelectionChange={value => {
          if (!value) {
            return;
          }
          setMarketId(Number(value));
          setSearchParams(
            params => {
              params.set('market', value.toString());
              return params;
            },
            { replace: true, preventScrollReset: true, state: { stateKey } },
          );
        }}
        selectedKey={marketId}
        shouldFocusWrap
      >
        {item => (
          <ComboboxItem key={item.id} textValue={item.name}>
            {item.name}
          </ComboboxItem>
        )}
      </Combobox>
    </Text>
  );
};
