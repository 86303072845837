import { MediaServerURL } from '@iheartradio/web.assets';
import { Playback } from '@iheartradio/web.playback';
import { slugify } from '@iheartradio/web.utilities';
import { isNonNullish, isNullish } from 'remeda';
import { $path } from 'remix-routes';

import { playlistAnalyticsData } from '~app/analytics/playlist-analytics-helper';
import { useItemSelected } from '~app/analytics/use-item-selected';
import type { FavoritesStation, MadeForYouRecs } from '~app/api/types';
import {
  CardCarousel,
  CardCarouselSlide,
} from '~app/components/card-carousel/card-carousel';
import {
  FeatureCard,
  FeatureCardImage,
} from '~app/components/feature-card/feature-card';
import { useIsMobile } from '~app/contexts/is-mobile';
import { useUser } from '~app/contexts/user';
import { Play } from '~app/playback/controls/play';
import { AnalyticsContext } from '~app/utilities/constants';
import { buildPlaylistUrl } from '~app/utilities/urls';
import { isAnonymousUser, isPremiumUser } from '~app/utilities/user';

const sectionTitle = 'Made For You';

function PlaylistSlide({
  playlist,
  index,
  sectionPosition,
  pageName,
}: {
  playlist: MadeForYouRecs[number];
  index: number;
  sectionPosition: number;
  pageName: string;
}) {
  const isMobile = useIsMobile();
  const { onItemSelected } = useItemSelected();

  const user = useUser();
  const isPremium = isPremiumUser(user);

  const type =
    isPremium ?
      Playback.StationType.Playlist
    : Playback.StationType.PlaylistRadio;

  const { playing } = Play.usePlaylistPlay({
    context: 0,
    id: `${Number(playlist?.userId)}::${playlist?.id}`,
    type,
  });

  const playlistAssets = playlistAnalyticsData({
    isCurated: playlist.curated,
    playlistId: playlist.id,
    profileId: user?.profileId?.toString() ?? '',
    playlistUserId: playlist.userId,
    playlistName: playlist.name,
    userType: user?.subscription?.type,
    isAnonymous: isAnonymousUser(user) ?? true,
    playlistType: type,
  });

  return (
    <CardCarouselSlide
      href={buildPlaylistUrl({ ...playlist })}
      onAction={() => {
        onItemSelected({
          pageName,
          section: slugify(sectionTitle),
          context: AnalyticsContext.Carousel,
          itemPosition: index,
          sectionPosition,
          assets: {
            asset: playlistAssets.asset,
          },
        });
      }}
    >
      <FeatureCard
        description={playlist.description}
        image={
          <FeatureCardImage
            alt={`Image for playlist: ${playlist.name}`}
            decoding="auto"
            index={index}
            src={playlist.urls?.image}
            width={isMobile ? 105 : 120}
          />
        }
        imageButton={
          <Play.Playlist
            context={0}
            id={`${Number(playlist?.userId)}::${playlist?.id}`}
            shuffle={false}
            size={48}
            type={type}
          />
        }
        isActive={playing}
        title={playlist.name}
      />
    </CardCarouselSlide>
  );
}

export function FavoritesSlide({
  mfr,
  index,
  sectionPosition,
  pageName,
}: {
  mfr: FavoritesStation;
  index: number;
  sectionPosition: number;
  pageName: string;
}) {
  const { onItemSelected } = useItemSelected();
  const isMobile = useIsMobile();

  const { description, name, seedProfileId } = mfr;

  const { playing } = Play.useFavoritesPlay({
    context: 0,
    id: seedProfileId,
  });

  return (
    <CardCarouselSlide
      href={$path('/favorites/:userId?', { userId: seedProfileId })}
      onAction={() => {
        onItemSelected({
          pageName,
          section: slugify(sectionTitle),
          context: AnalyticsContext.Carousel,
          assets: {
            asset: {
              id: `${Playback.StationType.Favorites}|${seedProfileId}`,
              name: name ?? '',
            },
          },
          sectionPosition,
          itemPosition: index,
        });
      }}
    >
      <FeatureCard
        description={description}
        image={
          <FeatureCardImage
            alt="Image for My Favorites Radio"
            index={index}
            src={MediaServerURL.fromCatalog({
              type: 'favorites',
              id: String(seedProfileId),
            })}
            width={isMobile ? 105 : 120}
          />
        }
        imageButton={
          <Play.Favorites context={0} id={seedProfileId} size={48} />
        }
        isActive={playing}
        title="My Favorites Radio"
      />
    </CardCarouselSlide>
  );
}

type MadeForYouCarouselItem =
  | { type: 'mfr'; index: number; data: FavoritesStation }
  | { type: 'playlistRec'; index: number; data: MadeForYouRecs[number] };

export function MadeForYouCarousel({
  myFavoritesRadio,
  playlistRecs,
  color,
  pageName,
  sectionPosition,
}: {
  myFavoritesRadio: FavoritesStation | null;
  playlistRecs: MadeForYouRecs;
  color?: string;
  pageName: string;
  sectionPosition: number;
}) {
  if (isNullish(playlistRecs) || playlistRecs.length < 2) {
    return null;
  }

  const carouselItems: MadeForYouCarouselItem[] = [
    myFavoritesRadio ?
      ({
        type: 'mfr',
        data: myFavoritesRadio,
        id: myFavoritesRadio.id,
      } as const)
    : null,
    ...playlistRecs.map(
      item =>
        ({
          type: 'playlistRec',
          data: item,
          id: item.id,
        }) as const,
    ),
  ]
    .filter(isNonNullish)
    .map((item, index) => ({ ...item, index }));

  return (
    <CardCarousel
      gradientColor={color}
      items={carouselItems}
      kind="featured"
      title={sectionTitle}
    >
      {carouselItem => {
        const { index, type, data } = carouselItem;
        return type === 'mfr' ?
            <FavoritesSlide
              index={index}
              mfr={data}
              pageName={pageName}
              sectionPosition={sectionPosition}
            />
          : <PlaylistSlide
              index={index}
              pageName={pageName}
              playlist={data}
              sectionPosition={sectionPosition}
            />;
      }}
    </CardCarousel>
  );
}
