/* eslint-disable prefer-const */

import { type ImageProps, Image } from '@iheartradio/web.assets';
import { type SlotProps, Slot } from '@radix-ui/react-slot';
import { filterDOMProps } from '@react-aria/utils';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import { clsx } from 'clsx/lite';
import {
  type ElementType,
  type ForwardedRef,
  type MouseEvent,
  type ReactElement,
  type ReactNode,
  createContext,
  forwardRef,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  type FocusVisibleProps,
  type FocusWithinProps,
  type HoverProps,
  FocusRing,
  mergeProps,
  useFocusVisible,
  useFocusWithin,
  useHover,
  useLink,
} from 'react-aria';
import type { LinkProps } from 'react-aria-components';
import { isFunction, omit, pick } from 'remeda';

import type { ElementProps, ValueOrBreakpointObject } from '../../types.js';
import {
  getResponsiveVar,
  variantsToDataAttrs,
} from '../../utilities/internal.js';
import {
  type CardPreviewVariants,
  type CardVariantProps,
  cardBodyStyles,
  cardImageStyles,
  cardPreviewOverlayButtonContainerStyles,
  cardPreviewOverlayStyles,
  cardPreviewRecipe,
  cardRecipe,
  cardSubtitleStyles,
  cardTitleStyles,
  DEFAULT_CARD_ORIENTATION,
  focusRingStyles,
  linesVarMap,
  linkCursorStyles,
} from './card.css.js';

export type LinesToShowProp = Partial<ValueOrBreakpointObject<1 | 2 | 3>>;

type CardContextProps = {
  isHovered: boolean;
  isActive: boolean;
  orientation: 'vertical' | 'horizontal';
  isFocused: boolean;
};

const CardContext = createContext<CardContextProps | null>(null);

function useCard() {
  const context = useContext(CardContext);

  if (!context) {
    throw new Error(`useCard must be used within a <Card />`);
  }

  return context;
}

export type CardProps = ElementProps<'div'> &
  LinkProps &
  HoverProps &
  FocusVisibleProps &
  FocusWithinProps &
  CardVariantProps & {
    children?: ReactNode;
    /** @default false */
    isActive?: boolean | undefined;
    isHovered?: boolean | undefined;
    isFocused?: boolean | undefined;
    'data-test'?: string;
  };
export function Card(props: CardProps) {
  let {
    children,
    className,
    isActive = false,
    isFocused: _isFocused,
    isHovered: _isHovered,
    onClick: deprecatedOnClick,
    'data-test': dataTest,
    ...otherProps
  } = props;

  const ref = useRef(null);
  const [isFocused, setIsFocused] = useState(false);

  const variantProps = pick(otherProps, cardRecipe.variants());
  otherProps = omit(otherProps, cardRecipe.variants());

  const LinkElementType: ElementType =
    props.href && !props.isDisabled ? 'a' : 'span';

  const { linkProps, isPressed } = useLink(
    { ...otherProps, elementType: LinkElementType },
    ref,
  );

  const { isHovered, hoverProps } = useHover(otherProps);
  const { isFocusVisible } = useFocusVisible(otherProps);
  const { focusWithinProps } = useFocusWithin({
    onFocusWithinChange: isFocusWithin => setIsFocused(isFocusWithin),
  });

  const DOMProps = filterDOMProps(otherProps);
  const orientation = variantProps.orientation ?? DEFAULT_CARD_ORIENTATION;

  const contextValue = useMemo(() => {
    return {
      isActive,
      isFocused: _isFocused ?? isFocused,
      isHovered: _isHovered ?? isHovered,
      orientation,
    };
  }, [_isHovered, isHovered, isActive, _isFocused, orientation, isFocused]);

  const allProps = mergeProps(hoverProps, focusWithinProps, {
    onClick: (e: MouseEvent<HTMLDivElement>) => {
      if (deprecatedOnClick) {
        deprecatedOnClick(e);
        console.warn('onClick is deprecated, please use onPress');
      }
    },
  });

  const articleContent = (
    <article
      {...DOMProps}
      {...allProps}
      {...variantsToDataAttrs(variantProps)}
      className={clsx(cardRecipe(variantProps), className)}
      data-active={isActive || undefined}
      data-focus-visible={isFocusVisible || undefined}
      data-focus-within={isFocused || undefined}
      data-focused={(_isFocused ?? isFocused) || undefined}
      data-hovered={(_isHovered ?? isHovered) || undefined}
      data-orientation={orientation}
      data-pressed={isPressed || undefined}
      data-test={dataTest}
      ref={ref}
    >
      {children}
    </article>
  );

  return (
    <CardContext.Provider value={contextValue}>
      <FocusRing focusRingClass={focusRingStyles} within>
        {props.href ?
          <a
            {...linkProps}
            className={linkCursorStyles}
            data-pressed={isPressed || undefined}
          >
            {articleContent}
          </a>
        : articleContent}
      </FocusRing>
    </CardContext.Provider>
  );
}

export type CardAnchorProps = SlotProps & {
  asChild?: boolean | undefined;
  href?: HTMLAnchorElement['href'];
};
export function CardAnchor({ asChild, href, ...props }: CardAnchorProps) {
  const Component = asChild ? Slot : 'a';
  return <Component href={href} {...props} />;
}

export type CardBodyProps = ElementProps<'article'> & {
  asChild?: boolean | undefined;
};
export const CardBody = forwardRef(function CardBody(
  props: CardBodyProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const { className, ...otherProps } = props;
  const { orientation } = useCard();

  return (
    <div
      {...otherProps}
      className={clsx(cardBodyStyles, className)}
      data-orientation={orientation}
      ref={ref}
    />
  );
});

export type CardTitleProps = ElementProps<'h4'> & {
  asChild?: boolean;
  lines?: LinesToShowProp;
};
export const CardTitle = forwardRef(function CardTitle(
  { asChild, className, lines, ...props }: CardTitleProps,
  ref: ForwardedRef<HTMLParagraphElement>,
) {
  const Component = asChild ? Slot : 'h4';
  const linesVars = getResponsiveVar(lines, linesVarMap);

  return (
    <Component
      className={clsx(cardTitleStyles, className)}
      {...props}
      ref={ref}
      slot="title"
      style={assignInlineVars({ ...linesVars })}
    />
  );
});

export type CardSubtitleProps = ElementProps<'p'> & {
  asChild?: boolean;
  lines?: LinesToShowProp;
};
export const CardSubtitle = forwardRef(function CardSubtitle(
  { asChild, className, lines, ...props }: CardSubtitleProps,
  ref: ForwardedRef<HTMLParagraphElement>,
) {
  const Component = asChild ? Slot : 'p';
  const linesVars = getResponsiveVar(lines, linesVarMap);

  return (
    <Component
      className={clsx(cardSubtitleStyles, className)}
      {...props}
      ref={ref}
      slot="description"
      style={assignInlineVars({ ...linesVars })}
    />
  );
});

export type CardPreviewProps = Omit<ElementProps<'div'>, 'children'> &
  CardPreviewVariants & {
    asChild?: boolean | undefined;
    children: ReactNode | ((props: CardContextProps) => ReactElement);
  };

export function CardPreview({
  asChild,
  className,
  children,
  shape,
  ...props
}: CardPreviewProps) {
  const Component = asChild && !isFunction(children) ? Slot : 'div';
  const cardContextValue = useCard();
  return (
    <Component
      {...props}
      className={clsx(
        cardPreviewRecipe({ shape, orientation: cardContextValue.orientation }),
        className,
      )}
      data-orientation={cardContextValue.orientation}
    >
      <CardPreviewOverlay />
      {isFunction(children) ? children(cardContextValue) : children}
    </Component>
  );
}

export type CardImageProps = ImageProps;
export function CardImage(props: CardImageProps) {
  return (
    <Image
      placeholder={false}
      {...props}
      classNames={{ image: cardImageStyles, placeholder: cardImageStyles }}
    />
  );
}

export function CardPreviewOverlay({
  className,
  ...props
}: ElementProps<'div'>) {
  return (
    <div {...props} className={clsx(cardPreviewOverlayStyles, className)} />
  );
}

export type CardPreviewOverlayButtonContainerProps = ElementProps<'div'> & {
  buttonPosition?: 'center' | 'bottomRight';
};
export function CardPreviewOverlayButtonContainer({
  className,
  buttonPosition = 'bottomRight',
  ...props
}: CardPreviewOverlayButtonContainerProps) {
  return (
    <div
      {...props}
      className={clsx(
        cardPreviewOverlayButtonContainerStyles({ buttonPosition }),
        className,
      )}
    />
  );
}
