import { assignInlineVars } from '@vanilla-extract/dynamic';
import { clsx } from 'clsx/lite';
import type { EmblaCarouselType, EmblaOptionsType } from 'embla-carousel';
import useEmblaCarousel from 'embla-carousel-react';
import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures';
import {
  type ForwardedRef,
  type ReactNode,
  createContext,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useId } from 'react-aria';
import type { GridListItemProps, GridListProps } from 'react-aria-components';
import { GridList, GridListItem } from 'react-aria-components';
import type { SetRequired } from 'type-fest';

import { ChevronLeft } from '../../icons/chevron-left.js';
import { ChevronRight } from '../../icons/chevron-right.js';
import { sprinkles } from '../../sprinkles.css.js';
import { vars } from '../../theme-contract.css.js';
import type {
  BreakpointObject,
  ElementProps,
  ValueOrBreakpointObject,
} from '../../types.js';
import { getResponsiveVar } from '../../utilities/internal.js';
import { Button } from '../button/index.js';
import {
  carouselButtonContainerStyles,
  carouselHandlerStyles,
  carouselSlidesContainerStyles,
  carouselSlideStyles,
  carouselStyles,
  carouselViewportStyles,
  peekSizeVarMap,
  slideGapVarMap,
  slidesToShowVarMap,
} from './carousel.css.js';

export type CarouselContextProps = {
  api: ReturnType<typeof useEmblaCarousel>[1];
  canScrollNext: boolean;
  canScrollPrev: boolean;
  carouselContainerId: string;
  carouselRef: ReturnType<typeof useEmblaCarousel>[0];
  scrollNext: () => void;
  scrollPrev: () => void;
} & SetRequired<CarouselProps, 'orientation' | 'slidesToShow'>;

const CarouselContext = createContext<CarouselContextProps | null>(null);

/**
 * A shared context which makes various pieces of Carousel state available to subcomponents.
 */
export const useCarousel = () => {
  const context = useContext(CarouselContext);

  if (!context) {
    throw new Error('useCarousel must be used within a <Carousel />');
  }

  return context;
};

const defaultSlidesToShow = {
  default: 3,
  xsmall: 4,
  small: 4,
  shmedium: 5,
  medium: 5,
  large: 6,
  xlarge: 7,
  xxlarge: 8,
} as const;

// These peek sizes DO NOT include the size of the gap between slides
const defaultPeekSizes = {
  default: '4rem',
  xsmall: '4rem',
  small: '4rem',
  shmedium: '5.6rem',
  medium: '5.6rem',
  large: '7.2rem',
  xlarge: '7.2rem',
  xxlarge: '7.2rem',
} as const;

export type PeekSize = BreakpointObject<string>;
export type SlideGap = BreakpointObject<string>;
export type SlidesToShow = BreakpointObject<number>;

export type CarouselProps = ElementProps<'div'> & {
  children?: ReactNode;
  isLoading?: boolean;
  /** @default 'horizontal' */
  orientation?: 'horizontal' | 'vertical';
  slideGap?: string | SlideGap;
  /** @default 'auto' */
  slidesToScroll?: number;
  /** @default 5 */
  slidesToShow?: Partial<ValueOrBreakpointObject<number>>;
  peekSize?: string | PeekSize;
  /** @default 'start' */
  align?: EmblaOptionsType['align'];
};

/**
 * Escapes CSS selectors that are created by React so that they are valid.
 * @param s
 * @returns
 */
function cssEscape(s: string) {
  return s.replaceAll(':', '\\:');
}

/**
 * The `Carousel` component shows a collection of items. The items are easily navigable with buttons, scrolling, or dragging.
 *
 * > This Carousel implementation uses <a href="https://www.embla-carousel.com/" target="_blank">Embla Carousel</a> under the hood. View those docs for more information and examples.
 * >
 * > Carousel Accessibility Best Practices: https://www.w3.org/TR/wai-aria-practices/#carousel
 */
export const Carousel = forwardRef(function Carousel(
  {
    children,
    isLoading,
    orientation = 'horizontal',
    peekSize = defaultPeekSizes,
    slideGap = vars.space[16],
    slidesToShow = defaultSlidesToShow,
    slidesToScroll,
    ...props
  }: CarouselProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const carouselContainerId = useId();

  const [carouselRef, api] = useEmblaCarousel(
    {
      align: 'start',
      /**
       * This is how we tell the Embla Carousel API which element is the "scrollable" container of
       * the carousel. This also has `ref` support but we MUST use a selector here because we can't
       * target the element we need with a ref.
       *
       * Embla uses the first child of the container ref we provide. The `GridList` component
       * (where we would like to attach the ref) has multiple children and the scrollable container
       * is not the first child. So, the reason we MUST use a selector here is because we don't
       * have a direct reference to the element we need. Passing an ID to the `GridList` and an
       * associated selector to `useEmblaCarousel` gives us the needed behavior.
       */
      container: '#' + cssEscape(carouselContainerId),
      axis: orientation === 'horizontal' ? 'x' : 'y',
      skipSnaps: true,
      slidesToScroll: slidesToScroll ?? 'auto',
    },
    [WheelGesturesPlugin()],
  );

  usePreventOverscroll(api);

  const { canScrollPrev, canScrollNext, scrollPrev, scrollNext } =
    usePrevNextButtons(api);

  const peekVars = getResponsiveVar(peekSize, peekSizeVarMap);
  const slidesToShowVars = getResponsiveVar(slidesToShow, slidesToShowVarMap);
  const slideGapVars = getResponsiveVar(slideGap, slideGapVarMap);

  const contextValue = useMemo(() => {
    return {
      api,
      canScrollNext,
      canScrollPrev,
      carouselContainerId,
      carouselRef,
      orientation,
      peekSize,
      scrollNext,
      scrollPrev,
      slidesToShow,
    };
  }, [
    api,
    canScrollNext,
    canScrollPrev,
    carouselContainerId,
    carouselRef,
    orientation,
    peekSize,
    scrollNext,
    scrollPrev,
    slidesToShow,
  ]);

  return (
    <CarouselContext.Provider value={contextValue}>
      <div
        aria-roledescription="carousel"
        data-loading={isLoading || undefined}
        role="region"
        {...props}
        className={carouselStyles}
        ref={ref}
        style={assignInlineVars({
          ...peekVars,
          ...slideGapVars,
          ...slidesToShowVars,
        })}
      >
        {children}
      </div>
    </CarouselContext.Provider>
  );
});

export const CarouselViewport = forwardRef(function CarouselViewport(
  props: ElementProps<'div'>,
  ref: ForwardedRef<HTMLDivElement>,
) {
  return <div {...props} className={clsx(carouselViewportStyles)} ref={ref} />;
});

export type CarouselContentProps<T extends object> = GridListProps<T>;

export function CarouselContent<T extends object>({
  ...props
}: CarouselContentProps<T>) {
  const { carouselRef, orientation, carouselContainerId } = useCarousel();

  return (
    <CarouselViewport ref={carouselRef}>
      <GridList
        aria-label="Slides"
        keyboardNavigationBehavior="tab"
        selectionMode="none"
        {...props}
        className={clsx(carouselSlidesContainerStyles)}
        id={carouselContainerId}
        layout={orientation === 'vertical' ? 'stack' : 'grid'}
      />
    </CarouselViewport>
  );
}

export type CarouselHandlerProps = ElementProps<'div'> & {
  children: ReactNode;
  /** @default true */
  showScrollButtons?: boolean;
};
export function CarouselHandler({
  children,
  className,
  showScrollButtons = true,
  ...props
}: CarouselHandlerProps) {
  const { canScrollPrev, canScrollNext, scrollNext, scrollPrev } =
    useCarousel();

  return (
    <div className={clsx(carouselHandlerStyles, className)} {...props}>
      <div className={sprinkles({ width: 'full' })}>{children}</div>
      {showScrollButtons ?
        <div className={carouselButtonContainerStyles}>
          <Button
            color={{ light: 'gray', dark: 'white' }}
            isDisabled={!canScrollPrev}
            kind="primary"
            onPress={scrollPrev}
            size="icon"
          >
            <ChevronLeft />
          </Button>
          <Button
            color={{ light: 'gray', dark: 'white' }}
            isDisabled={!canScrollNext}
            kind="primary"
            onPress={scrollNext}
            size="icon"
          >
            <ChevronRight />
          </Button>
        </div>
      : null}
    </div>
  );
}

export type CarouselSlideProps<T extends object> = GridListItemProps<T> & {
  'aria-label'?: string | undefined;
};
export function CarouselSlide<T extends object>({
  className,
  ...props
}: CarouselSlideProps<T>) {
  return (
    <GridListItem
      aria-label={props?.['aria-label'] ?? props?.id}
      aria-roledescription="slide"
      className={clsx(carouselSlideStyles, className)}
      textValue={
        props?.['aria-label'] ??
        (props && props.id ? String(props.id) : 'slide')
      }
      {...props}
    />
  );
}

type UsePrevNextButtonsProps = {
  canScrollPrev: boolean;
  canScrollNext: boolean;
  scrollPrev: () => void;
  scrollNext: () => void;
};

/**
 * This hook provides the necessary logic, state, and callbacks for previous and next buttons for an Embla carousel.
 */
function usePrevNextButtons(
  api: EmblaCarouselType | undefined,
): UsePrevNextButtonsProps {
  // Default to `false` since the carousel should be in the left-most position on initial render.
  const [canScrollPrev, setCanScrollPrev] = useState(false);
  const [canScrollNext, setCanScrollNext] = useState(true);

  const scrollPrev = useCallback(() => {
    if (!api) return;
    api.scrollPrev();
  }, [api]);

  const scrollNext = useCallback(() => {
    if (!api) return;
    api.scrollNext();
  }, [api]);

  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setCanScrollPrev(emblaApi.canScrollPrev());
    setCanScrollNext(emblaApi.canScrollNext());
  }, []);

  useEffect(() => {
    if (!api) return;

    onSelect(api);
    api.on('reInit', onSelect);
    api.on('select', onSelect);

    return () => {
      api?.off('reInit', onSelect);
      api?.off('select', onSelect);
    };
  }, [api, onSelect]);

  return {
    canScrollPrev,
    canScrollNext,
    scrollPrev,
    scrollNext,
  };
}

/**
 * This hook prevents an Embla carousel from "overscrolling", meaning the carousel cannot be dragged past the first and last slides.
 */
function usePreventOverscroll(api: EmblaCarouselType | undefined) {
  const onScroll = useCallback((api: EmblaCarouselType) => {
    const {
      limit,
      target,
      location,
      offsetLocation,
      scrollTo,
      translate,
      scrollBody,
    } = api.internalEngine();

    let edge: number | null = null;

    if (location.get() > limit.max) edge = limit.max;
    if (location.get() < limit.min) edge = limit.min;

    if (edge !== null) {
      offsetLocation.set(edge);
      location.set(edge);
      target.set(edge);
      translate.to(edge);
      translate.toggleActive(false);
      scrollBody.useDuration(0).useFriction(0);
      scrollTo.distance(0, false);
    } else {
      translate.toggleActive(true);
    }
  }, []);

  useEffect(() => {
    if (!api) return;

    onScroll(api);
    api.on('scroll', onScroll);

    return () => {
      api.off('scroll', onScroll);
    };
  }, [api, onScroll]);
}
