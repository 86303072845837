import { MediaServerURL } from '@iheartradio/web.assets';
import { Playback } from '@iheartradio/web.playback';
import { slugify } from '@iheartradio/web.utilities';

import { useItemSelected } from '~app/analytics/use-item-selected';
import {
  CardCarousel,
  CardCarouselSlide,
} from '~app/components/card-carousel/card-carousel';
import {
  ContentCard,
  ContentCardImage,
} from '~app/components/content-card/content-card';
import { useIsMobile } from '~app/contexts/is-mobile';
import { Play } from '~app/playback/controls/play';
import { AnalyticsContext } from '~app/utilities/constants';
import { buildPodcastUrl } from '~app/utilities/urls';

import type { HomeLoaderData } from '../_home';

function Slide({
  pageName,
  podcast,
  index,
  sectionPosition,
  section,
}: {
  pageName: string;
  podcast: HomeLoaderData['topPodcasts'][number];
  index: number;
  sectionPosition: number;
  section: string;
}) {
  const { onItemSelected } = useItemSelected();
  const isMobile = useIsMobile();

  const { playing } = Play.usePodcastPlay({
    id: Number(podcast.id),
    context: 0,
  });

  const podcastUrl = buildPodcastUrl({
    podcastId: Number(podcast.id),
    slug: 'slug' in podcast ? (podcast.slug as string) : '',
  });

  return (
    <CardCarouselSlide
      href={podcastUrl}
      onAction={() => {
        onItemSelected({
          pageName,
          section,
          context: AnalyticsContext.Carousel,
          itemPosition: index,
          sectionPosition,
          assets: {
            asset: {
              id: `${Playback.StationType.Podcast}|${podcast.id}`,
              name: podcast.title ?? '',
              type: Playback.StationType.Podcast,
            },
          },
        });
      }}
    >
      {renderProps => {
        const { isFocused, isHovered, isPressed } = renderProps;
        return (
          <ContentCard
            {...{ isFocused, isHovered, isPressed }}
            description={
              'description' in podcast ?
                podcast.description
              : (podcast.subtitle ?? '')
            }
            image={
              <ContentCardImage
                alt={podcast.title ?? ''}
                data-test="recommended-podscast-artwork"
                decoding="auto"
                index={index}
                src={MediaServerURL.fromCatalog({
                  type: 'podcast',
                  id: podcast.id,
                }).quality(isMobile ? 40 : 50)}
                width={isMobile ? 75 : 120}
              />
            }
            imageButton={
              <Play.Podcast context={0} id={Number(podcast.id)} size={48} />
            }
            isActive={playing}
            title={podcast.title ?? ''}
          />
        );
      }}
    </CardCarouselSlide>
  );
}

Slide.displayName = 'RecommendedPodcastsCarouselSlide';

type RecommendedPodcast = HomeLoaderData['topPodcasts'][number];

export function RecommendedPodcastsCarousel({
  pageName,
  podcasts,
  sectionPosition,
  title,
}: {
  pageName: string;
  podcasts: Array<RecommendedPodcast>;
  sectionPosition: number;
  title: string;
}) {
  const podcastItems = podcasts.map((x, index) => ({ ...x, index }));

  return (
    <CardCarousel items={podcastItems} kind="content" title={title}>
      {podcastItem => {
        const { index, ...podcast } = podcastItem;
        return (
          <Slide
            index={index}
            key={podcast.id}
            pageName={pageName}
            podcast={podcast}
            section={slugify(title)}
            sectionPosition={sectionPosition}
          />
        );
      }}
    </CardCarousel>
  );
}
