import { MediaServerURL } from '@iheartradio/web.assets';
import { Playback } from '@iheartradio/web.playback';
import { useTheme } from '@iheartradio/web.remix-shared/react/theme.js';
import { isBlank, isNotBlank, slugify } from '@iheartradio/web.utilities';
import { useFetcher } from '@remix-run/react';
import { isNonNullish } from 'remeda';

import { useItemSelected } from '~app/analytics/use-item-selected';
import {
  CardCarousel,
  CardCarouselSlide,
} from '~app/components/card-carousel/card-carousel';
import {
  ContentCard,
  ContentCardImage,
} from '~app/components/content-card/content-card';
import { MarketsDropdown } from '~app/components/markets-dropdown';
import { useIsMobile } from '~app/contexts/is-mobile';
import { SelectInteractionProvider } from '~app/contexts/select-interaction';
import { useRootLoaderData } from '~app/hooks/use-root-loader-data';
import { SET_MARKET_FETCHER_KEY } from '~app/hooks/use-set-market-id';
import { Play } from '~app/playback/controls/play';
import type { LivePlayProps } from '~app/playback/controls/play/live-play';
import type { LiveStationRec } from '~app/services/types';
import { AnalyticsContext } from '~app/utilities/constants';
import { buildLiveUrl } from '~app/utilities/urls';

const sectionTitle = 'Live Radio in';

function Slide({
  liveStation,
  sectionPosition,
  index,
  pageName,
}: {
  liveStation: LiveStationRec;
  sectionPosition: number;
  index: number;
  pageName: string;
}) {
  const { onItemSelected } = useItemSelected();
  const isMobile = useIsMobile();
  const theme = useTheme();

  const playProps: LivePlayProps = {
    context: 0,
    id: Number(liveStation?.id),
  };

  const { playing } = Play.useLivePlay(playProps);

  if (isBlank(liveStation)) {
    return null;
  }

  const liveUrl = liveStation.id ? buildLiveUrl({ ...liveStation }) : undefined;

  return (
    <CardCarouselSlide
      href={liveUrl}
      id={liveStation.id}
      key={liveStation.id}
      onAction={() => {
        onItemSelected({
          pageName,
          section: slugify(sectionTitle),
          context: AnalyticsContext.Carousel,
          itemPosition: index,
          sectionPosition,
          assets: {
            asset: {
              id: `${Playback.StationType.Live}|${liveStation?.id}`,
              name: liveStation?.name ?? '',
              type: Playback.StationType.Live,
            },
          },
        });
      }}
    >
      {renderProps => {
        const { isFocused, isHovered, isPressed } = renderProps;
        return (
          <ContentCard
            {...{ isFocused, isHovered, isPressed }}
            description={liveStation.description}
            href={liveUrl}
            image={
              <ContentCardImage
                alt={liveStation.name ?? ''}
                decoding="auto"
                index={index}
                // Add the correct background color by using MediaServer functions
                src={MediaServerURL.fromURL(liveStation.logo)
                  .quality(isMobile ? 40 : 50)
                  .new()
                  .flood(theme === 'dark' ? '#27292D' : '#FFF')
                  .swap()
                  .merge('over')}
                width={isMobile ? 75 : 150}
              />
            }
            imageButton={<Play.Live {...playProps} size={48} />}
            isActive={playing}
            title={liveStation.name}
          />
        );
      }}
    </CardCarouselSlide>
  );
}

Slide.displayName = 'LocalLiveStationsCarouselSlide';

export function LocalLiveStationsCarousel({
  liveStations,
  sectionPosition,
  pageName,
}: {
  liveStations: LiveStationRec[];
  sectionPosition: number;
  pageName: string;
}) {
  const { market } = useRootLoaderData();
  const fetcher = useFetcher({ key: SET_MARKET_FETCHER_KEY });

  return isNotBlank(liveStations) ?
      <SelectInteractionProvider>
        <CardCarousel
          dependencies={[market.marketId]}
          isLoading={fetcher.state === 'loading'}
          items={liveStations
            .filter(item => isNonNullish(item.id))
            .map((item, index) => ({ item, id: item.id!, index }))}
          kind="content"
          title={<MarketsDropdown sectionTitle={sectionTitle} />}
        >
          {({ index, item: liveStation }) => {
            return (
              <Slide
                index={index}
                key={liveStation.id}
                liveStation={liveStation}
                pageName={pageName}
                sectionPosition={sectionPosition}
              />
            );
          }}
        </CardCarousel>
      </SelectInteractionProvider>
    : null;
}
