import { Playback } from '@iheartradio/web.playback';
import { slugify } from '@iheartradio/web.utilities';
import { isEmpty, isNullish } from 'remeda';

import { useItemSelected } from '~app/analytics/use-item-selected';
import type { FeaturedPlaylistsResult } from '~app/api/types';
import {
  CardCarousel,
  CardCarouselSlide,
} from '~app/components/card-carousel/card-carousel';
import {
  ContentCard,
  ContentCardImage,
} from '~app/components/content-card/content-card';
import { useIsMobile } from '~app/contexts/is-mobile';
import { useUser } from '~app/contexts/user';
import { useGetImageDominantColor } from '~app/hooks/use-get-image-dominant-color';
import { Play } from '~app/playback/controls/play';
import { AnalyticsContext } from '~app/utilities/constants';
import { buildPlaylistUrl } from '~app/utilities/urls';
import { isPremiumUser } from '~app/utilities/user';

const sectionTitle = 'Featured Playlists';

function Slide({
  playlist,
  pageName,
  index,
  sectionPosition,
}: {
  playlist: FeaturedPlaylistsResult[number];
  pageName: string;
  index: number;
  sectionPosition: number;
}) {
  const isMobile = useIsMobile();
  const { onItemSelected } = useItemSelected();
  const user = useUser();
  const isPremium = isPremiumUser(user);

  const [userId, id] = String(playlist.catalog?.id).split('::');
  const type =
    isPremium ?
      Playback.StationType.Playlist
    : Playback.StationType.PlaylistRadio;

  const { playing } = Play.usePlaylistPlay({
    context: 0,
    id: `${Number(userId)}::${id}`,
    type,
  });

  return (
    <CardCarouselSlide
      href={buildPlaylistUrl({
        name: playlist.title ?? '',
        userId,
        id,
      })}
      key={playlist.id ?? playlist?.catalog?.id}
      onAction={() => {
        onItemSelected({
          pageName,
          section: slugify(sectionTitle),
          context: AnalyticsContext.Carousel,
          itemPosition: index,
          sectionPosition,
          assets: {
            asset: {
              id: `${type}|${playlist.catalog?.id}`,
              name: playlist.title ?? '',
            },
          },
        });
      }}
    >
      <ContentCard
        description={playlist.subtitle ?? ''}
        image={
          <ContentCardImage
            alt={playlist.title ?? ''}
            decoding="auto"
            index={index}
            src={playlist?.img_uri || undefined}
            width={isMobile ? 75 : 150}
          />
        }
        imageButton={
          <Play.Playlist
            context={0}
            id={`${Number(userId)}::${id}`}
            shuffle={false}
            size={48}
            type={type}
          />
        }
        isActive={playing}
        title={playlist.title ?? ''}
      />
    </CardCarouselSlide>
  );
}

export function FeaturedPlaylistsCarousel({
  pageName,
  playlists,
  sectionPosition,
}: {
  pageName: string;
  playlists: FeaturedPlaylistsResult | [];
  sectionPosition: number;
}) {
  const carouselBackgroundColor = useGetImageDominantColor(
    playlists?.[0]?.img_uri,
  );

  if (isNullish(playlists) || isEmpty(playlists)) {
    return null;
  }

  const carouselItems = playlists
    .filter(item => item.id || item?.catalog?.id)
    .map((item, index) => ({
      item,
      index,
      id: (item.id ?? item?.catalog?.id)!,
    }));

  return (
    <CardCarousel
      description="Explore latest releases, picked by iHeart for our dear listeners"
      gradientColor={carouselBackgroundColor}
      items={carouselItems}
      kind="content"
      title={sectionTitle}
    >
      {({ item: playlist, index }) => {
        return (
          <Slide
            index={index}
            pageName={pageName}
            playlist={playlist}
            sectionPosition={sectionPosition}
          />
        );
      }}
    </CardCarousel>
  );
}
