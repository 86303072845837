import {
  addToast,
  UpgradeCTANotification,
} from '@iheartradio/web.accomplice/toast';
import { Playback } from '@iheartradio/web.playback';
import { useNavigate } from '@remix-run/react';
import { useCallback } from 'react';

import { AdsTargetingState, useTargetingReady } from '~app/contexts/ads';
import { useConfig } from '~app/contexts/config';
import { useUser } from '~app/contexts/user';
import { isPremiumUser } from '~app/utilities/user';

import { playback } from '../../playback';
import { usePlay } from './use-play';

export type UseTopSongsPlayProps = Omit<
  Playback.TopSongsStation,
  'type' | 'targeting'
>;

export function useTopSongsPlay(props: UseTopSongsPlayProps) {
  const isPremium = isPremiumUser(useUser());
  const player = playback.usePlayer<
    Playback.TopSongsStation | Playback.ArtistStation
  >();
  const checkReadyToLoad = useTargetingReady();
  const config = useConfig();
  const navigate = useNavigate();

  const doLoadAndPlay = useCallback(async () => {
    await player.load({
      ...props,
      type: Playback.StationType.Artist,
      targeting: AdsTargetingState.get('targetingParams'),
    });
    await player.play();
  }, [player, props]);

  const play = usePlay({ ...props, type: Playback.StationType.TopSongs });

  async function onClick() {
    if (isPremium) {
      play.onClick();
      return;
    }

    addToast(
      UpgradeCTANotification({
        size: { xsmall: 'small', medium: 'large' },
        path: `${config.urls.account}/subscribe`,
        navigate,
      }),
    );

    if (checkReadyToLoad()) {
      doLoadAndPlay();
    } else {
      (function doCheck() {
        globalThis.window.setTimeout(() => {
          if (checkReadyToLoad()) {
            doLoadAndPlay();
          } else {
            doCheck();
          }
        }, 100);
      })();
    }
  }

  return { ...play, onClick } as const;
}
