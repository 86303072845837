import { MediaServerURL } from '@iheartradio/web.assets';
import { Playback } from '@iheartradio/web.playback';
import { isBlank, isNotBlank, slugify } from '@iheartradio/web.utilities';

import { useItemSelected } from '~app/analytics/use-item-selected';
import {
  CardCarousel,
  CardCarouselSlide,
} from '~app/components/card-carousel/card-carousel';
import {
  ContentCard,
  ContentCardImage,
} from '~app/components/content-card/content-card';
import { FollowButton } from '~app/components/follow-button';
import { useIsMobile } from '~app/contexts/is-mobile';
import { useFollowUnfollow } from '~app/hooks/use-follow-unfollow/use-follow-unfollow';
import { Play } from '~app/playback/controls/play';
import type { HomeClientLoaderData } from '~app/routes/api.v1.home';
import { AnalyticsContext } from '~app/utilities/constants';
import { buildArtistUrl } from '~app/utilities/urls';

export function Slide({
  artist,
  index,
  pageName,
  section,
  sectionPosition,
}: {
  artist: HomeClientLoaderData['recommendedArtists'][number];
  index: number;
  pageName: string;
  section: string;
  sectionPosition: number;
}) {
  const { onItemSelected } = useItemSelected();
  const isMobile = useIsMobile();
  const { follow, unfollow, isFollowing } = useFollowUnfollow({
    contentId: String(artist?.id),
    contentType: Playback.StationType.Artist,
    contentName: artist?.artistName ?? '',
    section: slugify(section),
    context: 'carousel',
    // removed `isFollowingStation` so that the hook will determine the follow status itself
  });

  const { playing } = Play.useArtistPlay({
    context: 0,
    id: Number(artist?.id),
  });

  if (isBlank(artist)) {
    return null;
  }

  const artistUrl =
    artist.id && artist.artistName ?
      buildArtistUrl({
        id: artist.id,
        name: artist.artistName,
      })
    : undefined;

  return (
    <CardCarouselSlide
      href={artistUrl}
      onAction={() => {
        onItemSelected({
          pageName,
          section: slugify(section),
          context: AnalyticsContext.Carousel,
          itemPosition: index,
          sectionPosition,
          assets: {
            asset: {
              id: `${Playback.StationType.Artist}|${artist?.id}`,
              name: artist?.artistName ?? '',
            },
          },
        });
      }}
    >
      <ContentCard
        actions={
          <FollowButton
            isFollowing={isFollowing}
            onFollow={() => {
              follow();
            }}
            onUnfollow={() => {
              unfollow();
            }}
          />
        }
        image={
          <ContentCardImage
            alt={artist.artistName ?? ''}
            decoding="auto"
            index={index}
            // 'Circle-ify' the image using MediaServer in order to remove the extra box, which
            // was causing some rendering issues
            src={MediaServerURL.fromCatalog({
              type: 'artist',
              id: String(artist.id),
            })
              .quality(isMobile ? 40 : 75)
              .run('circle')}
            width={isMobile ? 75 : 150}
          />
        }
        imageButton={
          <Play.Artist context={0} id={Number(artist?.id)} size={48} />
        }
        isActive={playing}
        linesForTitle={2}
        previewShape="circle"
        title={artist.artistName}
      />
    </CardCarouselSlide>
  );
}

Slide.displayName = 'RecommendedArtistsCarouselSlide';

export function RecommendedArtistsCarousel({
  artists,
  pageName,
  sectionPosition,
  sectionTitle,
}: {
  artists: HomeClientLoaderData['recommendedArtists'];
  pageName: string;
  sectionPosition: number;
  sectionTitle: string;
}) {
  const carouselItems = artists.map((item, index) => ({ ...item, index }));

  return isNotBlank(artists) ?
      <CardCarousel items={carouselItems} kind="content" title={sectionTitle}>
        {carouselItem => {
          const { index, ...artist } = carouselItem;
          return (
            <Slide
              artist={artist}
              index={index}
              pageName={pageName}
              section={sectionTitle}
              sectionPosition={sectionPosition}
            />
          );
        }}
      </CardCarousel>
    : null;
}
