import { vars } from '@iheartradio/web.accomplice';
import {
  MenuContent,
  MenuRoot,
  MenuTrigger,
} from '@iheartradio/web.accomplice/menu';
import {
  addToast,
  AuthenticateCTANotification,
} from '@iheartradio/web.accomplice/toast';
import {
  media,
  Player,
  Theme,
  ThemeProvider,
  useMediaQuery,
  useTheme,
} from '@iheartradio/web.companion';
import { isNull } from '@iheartradio/web.utilities';
import { useNavigate } from '@remix-run/react';
import { useCallback, useState } from 'react';
import { $path } from 'remix-routes';

import { useRegGateEvent } from '~app/analytics/use-reg-gate';
import type { CreatePlaylistSubmitCallback as CreateSubmitCallback } from '~app/components/dialogs/create-playlist-dialog';
import {
  type AddToCollectionSubmitCallback as AddSubmitCallback,
  AddToPlaylistSubMenu,
} from '~app/components/menu-items/add-to-playlist-sub-menu';
import { useUser } from '~app/contexts/user';
import {
  ADD_TO_PLAYLIST_AUTHENTICATION_MESSAGE,
  REG_GATE_TRIGGER_TYPES,
  Routes,
} from '~app/utilities/constants';
import { buildPlaylistUrl } from '~app/utilities/urls';

import { playback } from '../playback';

export function AddToPlaylist() {
  const user = useUser();
  const navigate = useNavigate();
  const { onAnalyticsRegGateOpen, onAnalyticsRegGateExit } = useRegGateEvent();
  const { station } = playback.useState();
  const metadata = playback.useMetadata();
  const isLarge = useMediaQuery(media.large);
  const [isFullScreen, setIsFullScreen] = Player.useFullScreen();
  const defaultTheme = useTheme();
  const theme = isFullScreen ? defaultTheme : Theme.Dark;
  // TODO: Refactor to a better solution - ref blur, ref focus, etc.
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const handleAddSubmit = useCallback<AddSubmitCallback>(data => {
    setMenuOpen(false);
    if (data?.ok) {
      addToast({
        kind: 'success',
        text: `Added to ${data.collection.name}`,
        actions: [
          {
            kind: 'tertiary',
            color: 'gray',
            content: 'Go to Playlist',
            size: 'large',
            textColor: vars.color.gray600,
            onPress: () => {
              setIsFullScreen(false);
              navigate(buildPlaylistUrl(data.collection));
            },
          },
        ],
      });
    }

    if (!data?.ok) {
      addToast({
        kind: 'error',
        text: "We can't add duplicate songs to a playlist.",
        title: 'These songs are already added',
      });
    }
  }, []);

  const handleCreateSubmit = useCallback<CreateSubmitCallback>(
    data => {
      setMenuOpen(false);

      if (data.ok) {
        addToast({
          kind: 'success',
          text: 'Playlist created',
          actions: [
            {
              kind: 'primary',
              color: 'gray',
              content: 'Go to Playlist',
              size: 'large',
              textColor: vars.color.gray600,
              onPress: () => {
                setIsFullScreen(false);
                navigate(buildPlaylistUrl(data.collection));
              },
            },
          ],
        });
      }
    },
    [setIsFullScreen],
  );

  if (isNull(station) || isNull(metadata)) {
    return null;
  }

  const { trackId, id } = metadata.data;

  if (user?.isAnonymous) {
    return (
      <Player.AddToPlaylist
        data-test="add-to-playlist-button"
        onClick={() => {
          addToast(
            AuthenticateCTANotification({
              trigger: REG_GATE_TRIGGER_TYPES.ADD_TO_PLAYLIST,
              text: ADD_TO_PLAYLIST_AUTHENTICATION_MESSAGE,
              size: { xsmall: 'small', medium: 'large' },
              paths: [
                $path(Routes.Login, { action: 'auth' }),
                $path(Routes.SignUp),
              ],
              navigate,
              onAnalyticsRegGateOpen,
              onAnalyticsRegGateExit,
            }),
          );
        }}
      />
    );
  }

  // TODO: CHECK THIS!
  return (
    <MenuRoot open={menuOpen}>
      <MenuTrigger asChild>
        <Player.AddToPlaylist
          data-test="add-to-playlist-menu-trigger"
          onClick={() => setMenuOpen(!menuOpen)}
        />
      </MenuTrigger>
      <MenuContent
        align={!isLarge && isFullScreen ? 'end' : 'start'}
        data-test="add-to-playlist-menu"
        onInteractOutside={() => setMenuOpen(false)}
        style={{
          maxHeight: '40dvh',
        }}
      >
        <ThemeProvider value={theme}>
          <AddToPlaylistSubMenu
            onAddSubmit={handleAddSubmit}
            onCreateSubmit={handleCreateSubmit}
            tracks={[Number(trackId ?? id)]}
          />
        </ThemeProvider>
      </MenuContent>
    </MenuRoot>
  );

  // TODO: REMOVE THIS
  // return (
  //   <Menu
  //     align={!isLarge && isFullScreen ? 'end' : 'start'}
  //     data-test="add-to-playlist-menu"
  //     maxHeight="40dvh"
  //     onInteractOutside={() => setMenuOpen(false)}
  //     open={menuOpen}
  //     trigger={
  //       <Player.AddToPlaylist
  //         data-test="add-to-playlist-menu-trigger"
  //         onClick={() => setMenuOpen(!menuOpen)}
  //       />
  //     }
  //   >
  //     <ThemeProvider value={theme}>
  //       <AddToPlaylistSubMenu
  //         onAddSubmit={handleAddSubmit}
  //         onCreateSubmit={handleCreateSubmit}
  //         tracks={[Number(trackId ?? id)]}
  //       />
  //     </ThemeProvider>
  //   </Menu>
  // );
}
