import { MediaServerURL } from '@iheartradio/web.assets';
import { isBlank, slugify } from '@iheartradio/web.utilities';

import { useItemSelected } from '~app/analytics/use-item-selected';
import {
  CardCarousel,
  CardCarouselSlide,
} from '~app/components/card-carousel/card-carousel';
import {
  GenreCard,
  GenreCardImage,
} from '~app/components/genre-card/genre-card';
import { useConfig } from '~app/contexts/config';
import { useIsMobile } from '~app/contexts/is-mobile';
import { AnalyticsContext } from '~app/utilities/constants';

import type { HomeLoaderData } from '../_home';

export function PopularNewsCarousel(props: {
  popularNewsItems: HomeLoaderData['popularNewsItems'];
  sectionPosition: number;
  pageName: string;
}) {
  const { popularNewsItems, sectionPosition, pageName } = props;
  const { onItemSelected } = useItemSelected();
  const config = useConfig();
  const isMobile = useIsMobile();

  if (isBlank(popularNewsItems)) {
    return null;
  }

  const sectionTitle = 'Popular News';

  const carouselItems = popularNewsItems
    .filter(item => item.type === 'PUBLISHING' && item.data?.id)
    .map((item, index) => ({ item, index, id: item.data.id! }));

  return (
    <CardCarousel items={carouselItems} kind="news" title={sectionTitle}>
      {({ index, item }) => {
        const newsItem = item.data;

        return (
          <CardCarouselSlide
            href={`${config.urls.content}${newsItem.slug}`}
            onAction={() => {
              if (pageName) {
                onItemSelected({
                  pageName,
                  context: AnalyticsContext.Carousel,
                  sectionPosition,
                  itemPosition: index,
                  section: slugify(sectionTitle),
                  assets: {
                    asset: {
                      id: `news|${newsItem.id}`,
                      name: newsItem.summary?.title,
                    },
                  },
                });
              }
            }}
            target="_blank"
          >
            {({ isFocused, isHovered, isPressed }) => (
              <GenreCard
                {...{ isFocused, isHovered, isPressed }}
                image={
                  <GenreCardImage
                    alt={newsItem.summary?.title}
                    decoding="auto"
                    index={index}
                    src={MediaServerURL.fromURL(newsItem.summary?.image)
                      .ratio(16, 9)
                      .quality(isMobile ? 40 : 75)}
                    width={isMobile ? 150 : 300}
                  />
                }
                title={newsItem.summary?.title}
              />
            )}
          </CardCarouselSlide>
        );
      }}
    </CardCarousel>
  );
}
