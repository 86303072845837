import type { BaseConfig } from '@iheartradio/web.config';
import { generateGlobalConfig } from '@iheartradio/web.config';
import { createConfigContext } from '@iheartradio/web.config/react';
import { type ReactNode, useEffect } from 'react';

export const configFactory = generateGlobalConfig();

// Default context value to US/prod config, this can be overridden in the root loader based on headers and query params
const { Provider, useConfig } = createConfigContext(
  configFactory.config.US.production,
);

const ConfigProvider = ({
  children,
  value,
}: {
  children: ReactNode;
  value: BaseConfig;
}) => {
  useEffect(() => {
    window.sessionStorage.setItem(
      'countryCode',
      String(value.environment.countryCode),
    );
  }, [value.environment.countryCode]);

  return <Provider value={value}>{children}</Provider>;
};

export { ConfigProvider, useConfig };
